

















































import Vue from 'vue';
import { TherapyTypeEnum, ITherapyContentAssessment, ITherapyContentGroup, ITherapyContent, ITherapyContentWithChoices, StyleTypeEnum, BranchingTypes, OutputTypes } from '@/scripts/store/modules/therapy/types';
import ElementCommentAdd from '@/views/products/components/element-comment-add.vue';
import ElementCommentList from '@/views/products/components/element-comment-list.vue';
import ElementContent from '@/views/products/components/element-content.vue';
import ElementDetails from '@/views/products/components/element-details.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';

const EXPRESSION_REGEX = /(?:\(*(#[A-Z0-9]+)\s*(==|<=|>=|<|>|!=)\s*([0-9]+|'?[0-9,]+'?|'NA')\)*)\s*(&&|\|\|)?/gi;

export default Vue.extend({
  name: 'element-view',
  components: {
    PrimaryButton,
    ElementCommentAdd,
    ElementCommentList,
    ElementContent,
    ElementDetails,
  },
  props: {
    therapyGroup: {
      type: Object as () => ITherapyContentGroup,
      required: true,
    },
    isPreview: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      commentListKey: 0,
    };
  },
  computed: {
    isMultiPath(): boolean {
      return this.therapyGroup.group.some(e => BranchingTypes.some(type => type === e.type));
    },
    hasOnlyNoInputElements(): boolean {
      return this.therapyGroup.group.every(e => OutputTypes.some(type => type === e.type));
    },
    showNextButton(): boolean {
      return !this.isPreview && (this.hasOnlyNoInputElements || (!this.isMultiPath && !this.showSkipButton && this.therapyGroup.group.every(e => e.answered || e.allowSkip)));
    },
    showSkipButton(): boolean {
      return !this.isPreview && !this.hasOnlyNoInputElements && !this.isMultiPath && this.therapyGroup.group.every(e => e.allowSkip && !e.answered);
    },
    showComments(): boolean {
      return !this.isPreview && this.therapyGroup.group.some(e => e.allowComments);
    },
    showFooter(): boolean {
      return !this.isPreview && !this.showNextButton && !this.showSkipButton && !this.showComments;
    },
    actionLabel(): string {
      const therapyContent = this.therapyGroup.group?.find(tc => tc.styleContent?.find(style => style.key === StyleTypeEnum.ACTION_LABEL));
      return therapyContent?.styleContent?.find(style => style.key === StyleTypeEnum.ACTION_LABEL)?.content || this.$t('common.actions.next') as string;
    },
    answersMap(): Map<string, number | string> {
      const answers = new Map<string, number | string>();
      this.therapyGroup.group.forEach(tc => {
        if (!tc.visible) {
          answers.set(tc.uen, 'NA');
        } else {
          const therapyContentWithChoices = tc as ITherapyContentWithChoices;
          if (therapyContentWithChoices.choices) {
            const selectedChoices = therapyContentWithChoices.choices.filter(choice => choice.selected);
            if (selectedChoices?.length) {
              answers.set(tc.uen, selectedChoices.map(choice => choice.id).join(','));
            } else {
              answers.set(tc.uen, 'NA');
            }
          }
        }
      });
      return answers;
    },
    visibleTherapyContent(): Array<ITherapyContent> {
      return this.therapyGroup.group.filter(tc => tc.visible);
    },
    enableFirstMargin(): boolean {
      const firstPart = this.visibleTherapyContent?.[0]?.styleContent?.[0];
      // Remove top padding if first tag is an image
      return !firstPart || firstPart.key !== StyleTypeEnum.GRAPHIC;
    },
  },
  mounted() {
    this.$log.debug('Displaying group', this.therapyGroup);
    this.resetBackground();
    // Add info to Jira Feedback
    this.addIssueInfo({ 'Element Data': JSON.stringify(this.therapyGroup) });
  },
  methods: {
    resetBackground(): void {
      const groupArea: HTMLDivElement = this.$refs.therapyGroupArea as HTMLDivElement;
      if (groupArea) {
        groupArea.style.removeProperty('min-height');
        groupArea.style.removeProperty('background');
      }
    },
    onAutoComplete(payload?: { therapyContent: ITherapyContent, pickup: boolean }): void {
      this.doCompleteGroup(payload);
    },
    doCompleteGroup(payload?: { therapyContent: ITherapyContent, pickup: boolean }): void {
      this.$emit('completedGroup', payload);
    },
    onCompleteElement(therapyContent: ITherapyContent) {
      this.therapyGroup.group.filter(tc => !!tc.displayCondition)
        .forEach(tc => {
          tc.visible = this.checkContentVisibility(tc.displayCondition || '');
        });
    },
    checkContentVisibility(expression: string): boolean {
      if (expression.replaceAll(/[\s\t\n\r]*/g, '').replaceAll(EXPRESSION_REGEX, '')) return false;

      const expressionString = expression.toUpperCase().replace(/#(\w+)/g, 'values.get(\'$1\')');
      const condition = this.readExpression(expressionString);

      return condition(this.answersMap) === true;
    },
    readExpression(expression: string) {
      // eslint-disable-next-line no-useless-call
      return Function.apply(null, [ 'values', 'return ' + expression ]);
    },
    doSkipGroup(): void {
      this.$log.debug('Skipping all group elements', this.therapyGroup);
      this.therapyGroup.group.forEach(e => {
        // If skipping, should not complete assessment
        if (e.type === TherapyTypeEnum.ASSESSMENT) {
          const assessment = e as ITherapyContentAssessment;
          assessment.userAssessmentId = undefined;
        }
      });
      this.doCompleteGroup(undefined);
    },
    updateBackground(bgStyle: CSSStyleDeclaration): void {
      if (!bgStyle || !this.$refs.elementGroupArea) return;

      const groupArea: HTMLDivElement = this.$refs.elementGroupArea as HTMLDivElement;

      const elementGroupAreaStyle: CSSStyleDeclaration = groupArea.style;
      elementGroupAreaStyle.minHeight = bgStyle.minHeight;
      elementGroupAreaStyle.background = bgStyle.background;
      elementGroupAreaStyle.backgroundPosition = bgStyle.backgroundPosition;
      elementGroupAreaStyle.backgroundSize = bgStyle.backgroundSize;
      elementGroupAreaStyle.backgroundRepeat = bgStyle.backgroundRepeat;
      elementGroupAreaStyle.backgroundColor = bgStyle.backgroundColor;
    },
    onCommentAdded() {
      this.commentListKey++;
    },
  },
});
