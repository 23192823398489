























































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import moment from 'moment';
import { IComment, ICommentStatus } from '@/scripts/store/modules/comments/types';
import AddReply from '@/views/products/components/comments/add-reply.vue';
import CommentCardBlock from '@/views/products/components/card-modal/comment-card-block.vue';
import CommentCardHide from '@/views/products/components/card-modal/comment-card-hide.vue';
import CommentReactions from '@/views/products/components/comments/comment-reactions.vue';
import StarlingAvatarImage from '@/views/avatar/starling-avatar-image.vue';

export default Vue.extend({
  name: 'comment-card',
  components: {
    StarlingAvatarImage,
    AddReply,
    CommentCardBlock,
    CommentCardHide,
    CommentReactions,
  },
  props: {
    comment: {
      type: Object as () => IComment,
      required: true,
    },
    repliesLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      hasBeenSeen: false,
      menuActive: false,
      editLoading: false,
      editActive: false,
      editText: '',
      isTimeVisible: false,
      translatedText: null,
      viewTranslation: false,
      showPreview: false,
      previewTherapy: null,
      replyComment: undefined as IComment | undefined,
      showAddReply: false,
      interacted: false,
      initiallyUnread: this.$store.getters['comments/getCommentsStatuses'].filter((cs: ICommentStatus) => cs.commentId == this.comment?.id || cs.replyId === this.comment?.id).some((cs: ICommentStatus) => !cs.read),
      showHideConfirmation: false,
      showBlockConfirmation: false,

    };
  },
  computed: {
    ...mapGetters({
      getCommentsStatuses: 'comments/getCommentsStatuses',
    }),
    formattedTime(): string {
      return moment(this.comment.timestamp).fromNow();
    },
    user(): Object {
      return this.$store.getters.user;
    },
    loading(): boolean {
      return this.$store.state.loadingSpinner;
    },
    actionList(): Object {
      const actions = this.$t('app.products.comments.comment_card.actions') as Object;

      return Object.entries(actions).filter(([ key, value ]) => {
        if (this.isHidden && !value.showHidden) return false;
        if (this.isAuthor && !value.showSameUser) return false;
        if (!this.isAuthor && !value.showOtherUser) return false;
        if (this.isModerator && !value.showModerator) return false;
        if (key === 'hide' && this.isHidden) return false;
        if (key === 'unhide' && !this.isHidden) return false;
        if (key === 'report' && this.comment.report.isReported) return false;
        if (key === 'reported' && !this.comment.report.isReported) return false;
        return true;
      }).map(([ key, value ]) => {
        return { action: key, ...value };
      });
    },
    textareaLabel(): TranslateResult {
      return this.$t('common.actions.edit');
    },
    isAuthor(): boolean {
      return Number(this.$store.getters.user.id) === this.comment.user.id;
    },
    isModerator(): boolean {
      return this.comment?.user?.moderator;
    },
    isUnread(): boolean {
      const foundStatuses = this.getCommentsStatuses.filter((cs: ICommentStatus) => cs.commentId == this.comment?.id || cs.replyId === this.comment?.id);
      return foundStatuses.some((cs: ICommentStatus) => !cs.read);
    },
    isHidden(): boolean {
      const foundStatuses = this.getCommentsStatuses.filter((cs: ICommentStatus) => (!this.comment?.parentId && cs.commentId == this.comment?.id && cs.replyId == this.comment?.id) || (!!this.comment?.parentId && cs.replyId == this.comment?.id));
      return foundStatuses.some((cs: ICommentStatus) => !!cs.hidden);
    },
    username(): any {
      const name = this.comment.user.codeName || 'codename';
      if (Number(this.$store.getters.user.id) !== this.comment.user.id) {
        return name;
      }
      return this.$t('app.products.comments.author', { name });
    },
  },
  watch: {
    menuActive(newValue) {
      this.$log.debug('Menu active changed', newValue);
    },
  },
  beforeCreate() {
    if (this.$options.components) {
      this.$options.components.ElementView = require('@/views/products/components/element-view.vue').default;
    }
  },
  mounted() {
    if (this.$data.initiallyUnread && !this.comment.parentId) {
      this.$emit('openUnreadReplies');
    } else if (!this.comment.parentId) {
      this.$emit('openLatestReply');
    }
  },
  methods: {
    markAsRead() {
      this.$data.interacted = true;
      if (this.isUnread && this.comment.parentId) {
        setTimeout(() => {
          this.$store.dispatch('comments/markCommentAsRead', this.comment.id);
        }, 1000);
      }
    },
    getReplies(): void {
      this.$data.menuActive = false;
      this.$emit('toggleReplies');
    },
    hideTranslation(): void {
      this.$data.viewTranslation = false;
      this.$data.translatedText = null;
    },
    menuSelect(item: { action: string, label: string; icon: string }): void {
      switch (item.action) {
        case 'guideline':
          this.$emit('showGuidline');
          break;

        case 'hide':
          this.showHideConfirmation = true;
          break;

        case 'block':
          this.showBlockConfirmation = true;
          break;

        case 'unhide':
          this.$store.dispatch('comments/markCommentAsUnhidden', this.comment.id).then(() => {
            this.$data.interacted = true;
          });
          break;

        case 'delete':
          this.$store.dispatch('comments/deleteComment', { comment: this.comment }).then(() => {
            this.$log.debug('Deleted comment');
            this.$emit('commentDeleted');
          });
          break;

        case 'edit':
          this.$data.editText = this.cleanComment(this.comment.text);
          this.$data.editActive = true;
          break;

        case 'report':
          this.$emit('reportComment');
          break;

        case 'reported':
          // do nothing
          break;

        case 'translate':
          if (this.$data.translatedText) {
            this.$data.viewTranslation = true;
          } else {
            this.$store.dispatch('comments/translateComment', this.comment.id).then(res => {
              this.$data.translatedText = res.value;
              this.$data.viewTranslation = true;
            });
          }
          break;
      }

      this.$data.menuActive = false;
    },
    editComment(): void {
      if (this.$data.editText) {
        this.$data.editLoading = true;
        this.$store
          .dispatch('comments/editComment', {
            ...this.comment,
            ...{ text: this.$data.editText },
          })
          .then(res => {
            if (res) {
              // eslint-disable-next-line vue/no-mutating-props
              this.comment.text = this.$data.editText;
              this.$data.editActive = false;
              this.$data.editLoading = false;
            }
          }).finally(() => {
            (this as any).markAsRead();
          });
      }
    },
    openPreview(uen: string): void {
      if (this.$data.previewTherapy) {
        this.$data.showPreview = true;
        return;
      }
      this.$log.debug('Loading therapy for uen ', uen);
      this.$store.commit('setLoadingView', true);
      this.$store
        .dispatch('therapy/getTherapyPreview', uen)
        .then(res => {
          this.$data.previewTherapy = res;
          this.$data.showPreview = true;
        })
        .finally(() => {
          (this as any).markAsRead();
          this.$store.commit('setLoadingView', false);
        });
    },
    openAddReply(replyComment: IComment): void {
      this.$data.replyComment = replyComment;
      this.$data.showAddReply = true;
    },
    onReplyAdded(): void {
      (this as any).markAsRead();
      this.$data.showAddReply = false;
      this.$data.replyComment = undefined;
      this.$data.showReplies = true;
    },
    visibilityChanged(isVisible: boolean, entry: any): void {
      if (isVisible && this.isUnread && this.comment.parentId) {
        this.$data.hasBeenSeen = true;
        this.$store.dispatch('comments/markCommentAsReadLater', this.comment.id);
      }
      if (!isVisible && this.$data.hasBeenSeen) {
        (this as any).markAsRead();
      }
    },
    cleanComment(text: string) {
      const regex = /<\/?[\w\s]*>|<.+[\W]>/g;
      return text.match(regex) ? text.replace(regex, '') : text;
    },
    onHideConfirm() {
      this.$store.dispatch('comments/markCommentAsHidden', this.comment.id).then(() => {
        this.$data.interacted = true;
      }).finally(() => {
        this.showHideConfirmation = false;
      });
    },
    onBlockConfirm() {
      this.$store.dispatch('comments/blockCommentAuthor', this.comment.id).then(() => {
        this.$emit('refreshList');
      }).finally(() => {
        this.showBlockConfirmation = false;
      });
    },
  },
});
