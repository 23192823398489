<template>
<v-dialog v-model="showModel"
        content-class="report-modal"
        @input="closeModel"
        :width="`${dialogMaxWidth}%`">
  <v-card class="report-card">
    <div class="text-right pr-3 pt-3">
        <v-btn class="ma-0" icon @click="closeModel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    <v-card-title>
      <h2 class="starling-h2 pl-1 pr-3 pb-3" style="
    display: block;
    width: 100%;">
        {{$t('app.products.comments.comment_card.report.title')}}
      </h2>
      <p class="starling-body pl-1 pr-1">{{$t('app.products.comments.comment_card.report.subtitle')}}</p>
    </v-card-title>
    <v-card-text>
      <div class="poll">
        <ul class="module-steps selectable-list">
          <li v-for="(choice, index) in $t('app.products.comments.comment_card.report.reasons')"
              :key="index"
              class="pl-3 pr-3 reason-item"
              :class="{'is-selected': reportReason === choice, 'reason-selected': reportReason === choice}"
              @click="reportReason = choice">
            <span class="starling-body">{{choice}}</span>
          </li>
        </ul>
      </div>
    </v-card-text>
    <v-card-actions>
        <div class="text-center pb-3">
          <v-btn
                  @click="closeModel">
            {{$t('common.actions.cancel')}}
          </v-btn>
          <primary-button
                  @click="reportComment()"
                  :large="$vuetify.breakpoint.smAndUp"
                  :loading="isLoading"
                  :disabled="!reportReason">
            {{$t('common.actions.report')}}
          </primary-button>
        </div>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button';
export default {
  name: 'reportModal',
  components: { PrimaryButton },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      reportReason: '',
      showModel: this.isActive,
    };
  },
  computed: {
    dialogMaxWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 400 / 12;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 800 / 12;
      }
      return 100;
    },
  },
  watch: {
    isActive: {
      immediate: true,
      handler (val) {
        this.showModel = val;
      },
    },
  },
  methods: {
    reportComment() {
      if (this.reportReason) {
        this.isLoading = true;
        const reportedComment = {
          ...this.comment,
          report: {
            isReported: true,
            title: this.reportReason,
          },
        };
        this.$store.dispatch('comments/reportComment', reportedComment).then(res => {
          // eslint-disable-next-line vue/no-mutating-props
          this.comment.report = reportedComment.report;
          this.isLoading = false;
          this.closeModel();
        });
      }
    },
    closeModel() {
      this.$emit('closeReportModal', false);
    },
  },
};
</script>

<style>
.report-modal, .report-card {
  border-radius: 15px;
}
.reason-item{
  border: 2px solid #04E7CB !important;
}
.reason-selected {
  background-color: #04E7CB !important;
}
</style>
