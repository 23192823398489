var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "element-carousel-item",
      attrs: { row: "", wrap: "", "fill-height": "" }
    },
    [
      _vm.headerContent
        ? _c("v-flex", { staticClass: "header-area", attrs: { xs12: "" } }, [
            _c(
              "div",
              { staticClass: "header-content" },
              [
                _c("element-styled-content", {
                  attrs: {
                    content: _vm.headerContent,
                    uen: _vm.uen,
                    removeBottomMargin: true
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-flex",
        { attrs: { xs12: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                "align-center": "",
                "justify-center": "",
                "fill-height": ""
              }
            },
            [
              _c("v-flex", { attrs: { shrink: "" } }, [
                _c(
                  "div",
                  { staticClass: "navigation-arrows" },
                  [
                    !_vm.isFirstPage
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ma-0",
                            attrs: { flat: "", icon: "", large: "" },
                            on: { click: _vm.onClickPrevious }
                          },
                          [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm.choiceContent
                ? _c(
                    "v-flex",
                    { attrs: { "py-3": "" } },
                    [
                      _c("element-styled-content", {
                        attrs: {
                          content: _vm.choiceContent,
                          uen: _vm.uen,
                          removeBottomMargin: true
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-flex", { attrs: { shrink: "" } }, [
                _c(
                  "div",
                  { staticClass: "navigation-arrows" },
                  [
                    !_vm.isLastPage
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "ma-0",
                            attrs: { flat: "", icon: "", large: "" },
                            on: { click: _vm.onClickNext }
                          },
                          [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }