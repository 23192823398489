var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", "align-center": "", "gap-xs-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { grow: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-textarea", {
                    staticClass: "add-comment-textarea",
                    attrs: {
                      counter: 1024,
                      label: _vm.$t("app.products.comments.comment.label"),
                      rules: _vm.textFieldRules,
                      readonly: _vm.isLoading,
                      rows: "1",
                      "auto-grow": "",
                      outline: ""
                    },
                    model: {
                      value: _vm.text,
                      callback: function($$v) {
                        _vm.text = $$v
                      },
                      expression: "text"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "primary-button",
                {
                  staticStyle: { "margin-bottom": "35px!important" },
                  attrs: {
                    disabled: !(
                      _vm.text &&
                      _vm.text.length > 0 &&
                      _vm.text.length <= 1024
                    ),
                    loading: _vm.isLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.postComment()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("common.actions.post")) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }