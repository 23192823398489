var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-mcq", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "gap-xs-2": "" } },
        _vm._l(_vm.therapyContent.choices, function(choice, index) {
          return _c(
            "v-flex",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple",
                  value: { class: "success--text choice-ripple" },
                  expression: "{ class: 'success--text choice-ripple' }"
                }
              ],
              key: index,
              attrs: { xs12: "" },
              on: {
                click: function($event) {
                  return _vm.selectChoice(choice)
                }
              }
            },
            [
              _c("element-mcq-choice", {
                attrs: { therapyChoice: choice, uen: _vm.therapyContent.uen }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }