






































































import Vue from 'vue';
import { ITherapyContent, ITherapyContentGroup, TherapyStatusEnum } from '@/scripts/store/modules/therapy/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';

export default Vue.extend({
  name: 'element-next-step',
  components: {
    ElementStyledContent,
  },
  props: {
    nextStep: {
      type: Object as () => ITherapyContentGroup,
      required: false,
    },
    practice: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      therapyContentGroup: undefined as ITherapyContentGroup | undefined,
      loading: true,
      noContent: false,
    };
  },
  computed: {
    actionLabel(): String | undefined {
      return this.actionButton ? this.$t('app.products.modules.button_start_session').toString() : undefined;
    },
    therapyContent(): ITherapyContent | undefined {
      return this.therapyContentGroup?.group?.[0];
    },
    allTherapiesComplete(): boolean {
      return this.noContent || this.therapyContentGroup?.status === TherapyStatusEnum.COMPLETED;
    },
  },
  mounted() {
    if (this.nextStep) {
      this.therapyContentGroup = this.nextStep;
      this.loading = false;
      this.$emit('loaded', {
        allCompleted: this.nextStep?.status === TherapyStatusEnum.COMPLETED,
        content: this.nextStep,
        empty: !this.nextStep?.group?.[0],
      });
      return;
    }
    this.getNextStep();
  },
  methods: {
    getNextStep() {
      this.loading = true;

      this.$store.dispatch('therapy/getNextStepSession', { practice: this.practice }).then((res: ITherapyContentGroup) => {
        if ((res as any).statusCode) {
          throw Error;
        }
        this.therapyContentGroup = res;
        this.noContent = false;
        this.$emit('loaded', {
          allCompleted: res?.status === TherapyStatusEnum.COMPLETED,
          content: res,
          empty: !res?.group?.[0],
        });
      }).catch(() => {
        this.noContent = true;
        this.$emit('loaded', { allCompleted: true, empty: true });
      }).finally(() => {
        this.loading = false;
      });
    },
    onCardClick() {
      if (this.actionButton) return;
      this.onActionButtonClick();
    },
    onActionButtonClick() {
      this.$emit('completeElement', {
        valid: true,
        autoComplete: true,
        therapyContent: this.therapyContent,
        pickup: true,
      });
    },
    goToTherapies() {
      this.$router.push({ name: 'therapies' });
    },
  },
});
