












































import Vue from 'vue';
import { ITherapyContentCarousel, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementCarouselItem from './element-carousel-item.vue';

export default Vue.extend({
  name: 'element-carousel',
  components: {
    ElementCarouselItem,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentCarousel,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 0,
    };
  },
  computed: {
    footerLabel(): String | undefined {
      return this.therapyContent.styleContent?.find(style => style.key === StyleTypeEnum.CONTENT)?.content;
    },
  },
  methods: {
    onClickNext () {
      if (this.currentPage + 1 >= this.therapyContent.choices.length) return;
      this.currentPage++;
    },
    onClickPrevious () {
      if (this.currentPage - 1 < 0) return;
      this.currentPage--;
    },
  },
});
