




















import Vue from 'vue';
import { ITherapyContentWorkbook } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-workbook',
  components: {},
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentWorkbook,
      required: true,
    },
  },
  data() {
    return {
      textFieldRules: [
        (v: string) => !!v || this.$t('common.errors.required'),
      ],
      valid: false,
    };
  },
  mounted() {
    const form = this.$refs.form as HTMLFormElement;
    this.$emit('completeElement', { valid: form.validate() });
  },
  methods: {
    completeElement() {
      const form = this.$refs.form as HTMLFormElement;
      this.$emit('completeElement', { valid: form.validate() });
    },
    findPlaceholder(): string {
      if (!this.therapyContent.styleContent) return '';
      const placeholder = this.therapyContent.styleContent.find(sc => sc.key === 'placeholder');
      return placeholder ? placeholder.content : '';
    },
  },
});
