
















import Vue from 'vue';
import { IMoodTracker, MoodType, MoodTypeEnum } from '@/scripts/store/modules/tools/moods/types';
import { IStyleContentMood, ITherapyContentMood, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import Checkmark from '@/views/components/utilities/checkmark.vue';
import MoodCard from '@/views/tools/moods/components/moodCard/mood-card.vue';
import MoodForm from '@/views/tools/moods/components/mood-form.vue';
import moment from 'moment';

export default Vue.extend({
  name: 'element-mood',
  components: {
    Checkmark,
    MoodCard,
    MoodForm,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentMood,
      required: true,
    },
  },
  data() {
    return {
      loadedMood: false,
      loading: false,
      linkedMood: {} as IMoodTracker,
      key: 0,
      editing: false,
    };
  },
  computed: {
    contentConfig(): IStyleContentMood | undefined {
      return this.therapyContent.styleContent?.find(style => style.key === StyleTypeEnum.CONTENT) as IStyleContentMood;
    },
    presetMoods(): MoodType {
      const moods = this.contentConfig?.moods?.split(',');
      return Object.values(MoodTypeEnum).reduce((previous, value) => ({ ...previous, [value]: { selected: moods?.includes(value), value: null } }), {}) as MoodType;
    },
    visibleFields(): string[] | undefined {
      return this.contentConfig?.fields?.split(',');
    },
  },
  mounted() {
    this.$log.debug('Mood element', this.therapyContent);
    this.$emit('completeElement', { valid: false });

    if (!this.therapyContent.userMoodId) {
      this.resetConfig(false);
      this.loadedMood = true;
      return;
    }

    this.$store.dispatch('moods/getMood', this.therapyContent.userMoodId).then(res => {
      if (!res || res.status >= 300) throw new Error();
      this.linkedMood = res;
      this.$emit('completeElement', { valid: true });
      this.resetConfig(true);
    }).catch(() => {
      this.resetConfig(false);
    }).finally(() => {
      this.loadedMood = true;
    });
  },
  methods: {
    resetConfig(keepValues: boolean) {
      if (!this.linkedMood.moodIntensities) {
        this.linkedMood.moodIntensities = {} as MoodType;
      }
      if (this.contentConfig?.content) {
        this.linkedMood.reasonLabel = this.contentConfig?.content;
      }
      if (this.visibleFields) {
        this.linkedMood.visibleFields = this.visibleFields;
        if (!this.contentConfig?.moods && this.visibleFields.every(field => field !== 'selector')) {
          this.linkedMood.visibleFields?.push('selector');
        }
      }

      if (!keepValues) {
        Object.entries(this.presetMoods).forEach(([ mood, selection ]) => {
          Object.assign(this.linkedMood.moodIntensities as any, {
            [mood]: {
              selected: selection?.selected || false,
              value: selection?.value || null,
            },
          });
        });

        const days = this.contentConfig?.date;
        const hours = this.contentConfig?.time;
        let moodDate = moment();

        if (hours !== undefined) {
          moodDate = moment({ hour: parseInt(hours) });
        } else if (this.visibleFields?.every(field => field !== 'time')) {
          moodDate = moment({ hour: 12 });
        }

        if (days) {
          moodDate = moodDate.subtract(parseInt(days), 'days');
        } else if (this.visibleFields?.every(field => field !== 'date')) {
          moodDate = moodDate.subtract(1, 'days');
        }

        this.linkedMood.date = moodDate.format();
      }
    },
    createMood(mood: IMoodTracker) {
      this.$log.debug('Create mood', mood);
      this.loading = true;
      this.$store.dispatch('moods/postMood', mood)
        .then(res => {
          if (res && !(res.status > 300)) {
            this.linkedMood = res;
            if (this.visibleFields) {
              this.linkedMood.visibleFields = this.visibleFields;
            }
            // eslint-disable-next-line vue/no-mutating-props
            this.therapyContent.userMoodId = parseInt(this.linkedMood.id);
            this.$emit('completeElement', { valid: true });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMood(mood: IMoodTracker) {
      this.$log.debug('Update existing mood', mood);
      this.loading = true;
      this.$store.dispatch('moods/editMood', mood)
        .then(res => {
          if (res && !(res.status > 300)) {
            this.linkedMood = res;
            if (this.visibleFields) {
              this.linkedMood.visibleFields = this.visibleFields;
            }
            // eslint-disable-next-line vue/no-mutating-props
            this.therapyContent.userMoodId = parseInt(this.linkedMood.id);
            this.$emit('completeElement', { valid: true });
          }
        })
        .finally(() => {
          this.loading = false;
          this.editing = false;
        });
    },
    editMood() {
      this.editing = true;
      this.key++;
      this.$emit('completeElement', { valid: false });
    },
    deleteMood(mood: IMoodTracker) {
      this.$store.dispatch('moods/deleteMood', mood.id)
        .then(() => {
          this.key++;
          this.linkedMood = {} as IMoodTracker;
          this.resetConfig(false);
          // eslint-disable-next-line vue/no-mutating-props
          this.therapyContent.userMoodId = undefined;
          this.$emit('completeElement', { valid: false });
        })
        .finally(() => {
          this.editing = false;
        });
    },
  },
});
