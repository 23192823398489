














import Vue from 'vue';
import { ITherapyContent } from '@/scripts/store/modules/therapy/types';
import { IComment, ICommentPage, ICommentStatus } from '@/scripts/store/modules/comments/types';
import CommentsContainer from '@/views/products/components/comments/comments-container.vue';

export default Vue.extend({
  name: 'element-comment-list',
  components: {
    CommentsContainer,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContent,
      required: true,
    },
  },
  data() {
    return {
      sortBy: null as string | null,
      sortDir: null as string | null,
      activePage: 0,
    };
  },
  computed: {
    comments(): Array<IComment> {
      return this.$store.getters['comments/getComments'];
    },
    totalComments(): Array<IComment> {
      return this.$store.getters['comments/getTotalComments'];
    },
    loading(): boolean {
      return this.$store.state.loadingSpinner;
    },
  },
  mounted() {
    this.loadComments(1);
  },
  methods: {
    loadComments(page: number) {
      this.$store.commit('setLoadingSpinner', true);
      const config = {
        uen: this.therapyContent.uen,
        page: page > 0 ? page - 1 : 0,
        sort: this.sortBy || 'id',
        dir: this.sortDir || 'DESC',
      };

      Promise.all([
        this.$store.dispatch('comments/getCommentsByUen', config).then((res: ICommentPage) => {
          this.activePage = res.number + 1;
        }),
        this.$store.dispatch('comments/getCommentsStatusesByUser').then((res: ICommentStatus[]) => {
          return res;
        }),
      ]).finally(() => {
        this.$store.commit('setLoadingSpinner', false);
      });
    },
    onSortComments(sortBy: string, sortDir: string) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      this.loadComments(1);
    },
  },
});
