var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedThought
    ? _c(
        "v-layout",
        { staticClass: "element-thoughts", attrs: { row: "", wrap: "" } },
        [
          !_vm.linkedThought.id
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("thought-form", {
                    attrs: {
                      thought: _vm.linkedThought,
                      loading: _vm.loading,
                      inline: ""
                    },
                    on: { "thought-submit": _vm.createThought }
                  })
                ],
                1
              )
            : _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("checkmark"),
                  _c("h4", { staticClass: "starling-h4 text-center bold" }, [
                    _vm._v(
                      _vm._s(_vm.$t("app.products.element.thought.title")) + "!"
                    )
                  ]),
                  _c("p", {
                    staticClass: "text-center starling-body mb-3",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("app.products.element.thought.subtitle")
                      )
                    }
                  }),
                  !_vm.editing
                    ? _c("thought-card", {
                        key: _vm.key,
                        attrs: { thought: _vm.linkedThought },
                        on: { edit: _vm.editThought, delete: _vm.deleteThought }
                      })
                    : _vm._e(),
                  _vm.editing
                    ? _c("thought-form", {
                        attrs: {
                          thought: _vm.linkedThought,
                          loading: _vm.loading,
                          inline: ""
                        },
                        on: { "thought-submit": _vm.updateThought }
                      })
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }