


































import Vue from 'vue';
import { IComment } from '@/scripts/store/modules/comments/types';
import CommentCard from '@/views/products/components/comments/comment-card.vue';
import ReportModal from '@/views/products/components/comments/report-modal.vue';

export default Vue.extend({
  name: 'comment-replies',
  components: {
    CommentCard,
    ReportModal,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    replies: {
      type: Array as () => Array<IComment>,
      required: true,
    },
    replyCount: {
      type: Number,
      required: true,
    },
    replyShown: {
      type: Number,
      required: true,
    },
    addReplyVisible: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      reportModalActive: false,
      reportedComment: {} as IComment,
    };
  },
  computed: {
    shownReplies(): any[] {
      return this.replies.slice(0, Math.min(this.replyShown, this.replyCount));
    },
    lastShownReply(): any {
      return this.shownReplies && this.shownReplies.length ? this.shownReplies[this.shownReplies.length - 1] : null;
    },
    isAuthor(): boolean {
      if (!this.lastShownReply) return false;
      return Number(this.$store.getters.user.id) === this.lastShownReply.user.id;
    },
    isModerator(): boolean {
      return this.lastShownReply?.user?.moderator;
    },
  },
  methods: {
    openAddReply(reply: IComment) {
      this.$emit('openAddReply', reply);
    },
    loadMoreReplies() {
      this.$emit('loadMoreReplies');
    },
    onRefreshList() {
      this.$emit('refreshList');
    },
    openReportModal(reply: IComment) {
      this.$data.reportedComment = reply;
      this.$data.reportModalActive = true;
    },
    closeReportModal() {
      this.$data.reportModalActive = false;
      this.$data.reportedComment = {} as IComment;
    },
    openGuidlineModal() {
      this.$emit('showGuidline');
    },
  },
});
