









































import Vue from 'vue';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import { IStyleContent, ITherapyChoiceMSQ, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import cssVars from 'css-vars-ponyfill';

export default Vue.extend({
  name: 'element-msq-choice',
  components: { ElementStyledContent },
  props: {
    therapyChoice: {
      type: Object as () => ITherapyChoiceMSQ,
      required: true,
    },
    isCardSelection: {
      type: Boolean,
      required: false,
    },
    uen: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasStyleContent(): boolean {
      if (!this.therapyChoice.styleContent || this.therapyChoice.styleContent.length === 0) return false;
      if (this.therapyChoice.styleContent.length > 1) return true;
      return this.therapyChoice.styleContent[0].key != StyleTypeEnum.CONTENT;
    },
    content(): IStyleContent[] {
      if (this.hasStyleContent) return this.therapyChoice.styleContent.filter(part => part.key != StyleTypeEnum.CONTENT);
      return [ { key: StyleTypeEnum.BODY, content: this.therapyChoice.content } ];
    },
  },
  mounted() {
    cssVars({});
  },
});
