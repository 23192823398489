















import Vue from 'vue';
import { ITherapyContentVideo } from '@/scripts/store/modules/therapy/types';
import vimeoVideo from '@/views/components/utilities/vimeo-video.vue';

export default Vue.extend({
  name: 'element-video',
  components: { vimeoVideo },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentVideo,
      required: true,
    },
  },
  methods: {
    onVideoEvent(event: string) {
      this.$store.dispatch('therapy/watchVideoEvent', { uen: this.therapyContent.uen, videoId: this.therapyContent.url, status: event });
    },
  },
});
