
















import Vue from 'vue';
import { IStyleContentJournal, ITherapyContentJournal, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import { IJournal } from '@/scripts/store/modules/tools/journals/types';
import JournalForm from '@/views/tools/journal/components/journal-form.vue';
import JournalCard from '@/views/tools/journal/components/journal-card.vue';

export default Vue.extend({
  name: 'element-journal',
  components: {
    JournalForm,
    JournalCard,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentJournal,
      required: true,
    },
  },
  data() {
    return {
      loadedJournal: false,
      loading: false,
      linkedJournal: {} as IJournal,
      editing: false,
    };
  },
  computed: {
    contentConfig(): IStyleContentJournal | undefined {
      return this.therapyContent.styleContent?.find(style => style.key === StyleTypeEnum.CONTENT) as IStyleContentJournal;
    },
    presetTitle(): string {
      if (!this.contentConfig?.content) return '';
      const titleRegex = /<title>\s*(.*?)\s*<\/title>/gi;
      const removeHtmlRegex = /(<\/?.*?>)/g;
      const titleText = titleRegex.exec(this.contentConfig.content)?.[1]?.replaceAll(removeHtmlRegex, '').trim();
      if (!titleText) return '';
      return titleText.length > 255 ? titleText.substring(0, 255) : titleText;
    },
  },
  mounted() {
    this.$emit('completeElement', { valid: false });
    if (this.therapyContent.userJournalId) {
      this.$store.dispatch('journals/getJournal', this.therapyContent.userJournalId).then(res => {
        if (!res || res.status >= 300) throw new Error();
        this.linkedJournal = res;
        this.editing = false;
        this.resetConfig(true);
        this.$emit('completeElement', { valid: true });
      }).catch(() => {
        this.resetConfig(false);
      }).finally(
        () => {
          this.loadedJournal = true;
        },
      );
    } else {
      this.resetConfig(false);
      this.loadedJournal = true;
      this.editing = true;
    }
  },
  methods: {
    resetConfig(keepValues: boolean) {
      if (!this.linkedJournal.uen) {
        this.linkedJournal.uen = this.therapyContent.uen;
      }
      if (!keepValues) {
        this.linkedJournal.title = this.presetTitle;
      }
    },
    onEdit() {
      this.editing = true;
      this.$emit('completeElement', { valid: false });
    },
    onCancel() {
      this.editing = false;
    },
    onUpdate(journal: IJournal) {
      this.linkedJournal = journal || {};
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.userJournalId = journal?.id ? parseInt(journal.id) : undefined;
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.userAnswer = journal?.description ? journal.description : undefined;
      this.resetConfig(!!journal);
      this.editing = false;
      this.$emit('completeElement', { valid: !!journal });
    },
  },
});
