var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-submenu pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
        _vm._l(_vm.therapyContent.choices, function(choice, index) {
          return _c("element-submenu-choice", {
            key: index,
            attrs: { choice: choice, uen: _vm.therapyContent.uen },
            on: { choiceSelected: _vm.choiceSelected }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }