









import Vue from 'vue';
import { IStyleContentGoal, ITherapyContentGoal, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import { GoalRepeatFrequencyEnum, IGoalSetter, IGoalSetterTask } from '@/scripts/store/modules/tools/goals/types';
import GoalCard from '@/views/tools/goals/components/goal-card.vue';
import GoalForm from '@/views/tools/goals/components/goal-form.vue';
import moment from 'moment';

export default Vue.extend({
  name: 'element-goal',
  components: {
    GoalCard,
    GoalForm,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentGoal,
      required: true,
    },
  },
  data() {
    return {
      loadedGoal: false,
      loading: false,
      linkedGoal: {} as IGoalSetter,
      editing: false,
    };
  },
  computed: {
    contentConfig(): IStyleContentGoal | undefined {
      return this.therapyContent.styleContent?.find(style => style.key === StyleTypeEnum.CONTENT) as IStyleContentGoal;
    },
    visibleFields(): string[] | undefined {
      return this.contentConfig?.fields?.split(',');
    },
    presetTitle(): string {
      if (!this.contentConfig?.content) return '';
      const titleRegex = /<title>\s*(.*?)\s*<\/title>/gi;
      const removeHtmlRegex = /(<\/?.*?>)/g;
      const titleText = titleRegex.exec(this.contentConfig.content)?.[1]?.replaceAll(removeHtmlRegex, '').trim();
      if (!titleText) return '';
      return titleText.length > 255 ? titleText.substring(0, 255) : titleText;
    },
    presetTasks(): IGoalSetterTask[] {
      if (!this.contentConfig?.content) {
        return [ { id: '', description: '', completed: false } ];
      }
      const tasks: IGoalSetterTask[] = [];
      const taskRegex = /<(?:description|task)>\s*(.*?)\s*<\/(?:description|task)>/gi;
      const removeHtmlRegex = /(<\/?.*?>)/g;
      let regexResults;
      while ((regexResults = taskRegex.exec(this.contentConfig.content)) !== null) {
        const taskText = regexResults[1] ? regexResults[1].replaceAll(removeHtmlRegex, '').trim() : '';
        tasks.push({ id: '', description: taskText, completed: false });
      }
      if (!tasks.length || tasks.length < 5) tasks.push({ id: '', description: '', completed: false });
      return tasks;
    },
    presetReminder(): number | undefined {
      return this.contentConfig?.reminder ? parseInt(`${this.contentConfig.reminder}`) : undefined;
    },
    presetRepeat(): GoalRepeatFrequencyEnum[] | undefined {
      if (!this.contentConfig?.repeat?.trim()) return;
      return this.contentConfig.repeat.trim().split(',').map(day => GoalRepeatFrequencyEnum[day.trim().toUpperCase() as keyof typeof GoalRepeatFrequencyEnum]);
    },
  },
  mounted() {
    this.$log.debug('Goal element', this.therapyContent);
    this.$emit('completeElement', { valid: false });
    if (this.therapyContent.userGoalId) {
      this.$store.dispatch('goals/getGoal', this.therapyContent.userGoalId).then(res => {
        if (!res || res.status >= 300) throw new Error();
        this.linkedGoal = res;
        this.resetConfig(true);
        this.$emit('completeElement', { valid: true });
      }).catch(() => {
        this.resetConfig(false);
      }).finally(
        () => {
          this.loadedGoal = true;
        },
      );
    } else {
      this.resetConfig(false);
      this.loadedGoal = true;
    }
  },
  methods: {
    resetConfig(keepValues: boolean) {
      if (!this.linkedGoal.uen) {
        this.linkedGoal.uen = this.therapyContent.uen;
      }

      if (this.visibleFields) {
        this.linkedGoal.visibleFields = this.visibleFields;
      }

      if (!keepValues) {
        this.linkedGoal.title = this.presetTitle;
        this.linkedGoal.tasks = this.presetTasks;
        this.linkedGoal.reminderInterval = this.presetReminder;
        this.linkedGoal.repeatFrequency = this.presetRepeat;
      }

      const days = this.contentConfig?.date;
      const hours = this.contentConfig?.time;
      let goalDate = null;

      if (hours !== undefined) {
        goalDate = moment().hours(parseInt(hours)).minutes(0);
      } else if (this.visibleFields?.every(field => field !== 'time')) {
        goalDate = this.calculateClosestSelectableTime();
      }

      if (days !== undefined) {
        goalDate = (goalDate || this.calculateClosestSelectableTime()).add(parseInt(days), 'days');
      } else if (this.visibleFields?.every(field => field !== 'date')) {
        goalDate = (goalDate || this.calculateClosestSelectableTime());
      }

      if (goalDate) {
        this.linkedGoal.startDate = goalDate.toDate();
      }
    },
    onEdit() {
      this.editing = true;
      this.$emit('completeElement', { valid: false });
    },
    onCancel() {
      this.editing = false;
    },
    onUpdate(goal: IGoalSetter) {
      this.linkedGoal = goal || {};
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.userGoalId = goal?.id ? parseInt(goal.id) : undefined;
      this.resetConfig(!!goal);
      this.editing = false;
      this.$emit('completeElement', { valid: !!goal });
    },
    calculateClosestSelectableTime() {
      const minuteInterval = 30;
      const goalDate = moment();

      if (goalDate.minutes() % minuteInterval != 0) {
        const interval = Math.ceil(goalDate.minutes() / minuteInterval);
        goalDate.minutes(interval * minuteInterval);
      }
      return goalDate;
    },
  },
});
