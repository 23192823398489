











import Vue from 'vue';
import { ITherapyContent } from '@/scripts/store/modules/therapy/types';
import AddComment from '@/views/products/components/comments/add-comment.vue';

export default Vue.extend({
  name: 'element-comment-add',
  components: {
    AddComment,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContent,
      required: true,
    },
  },
  methods: {
    onCommentAdded() {
      this.$emit('commentAdded');
    },
  },
});
