






















import Vue from 'vue';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import { IStyleContent, ITherapyChoiceMCQ, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import cssVars from 'css-vars-ponyfill';

export default Vue.extend({
  name: 'element-mcq-choice',
  components: { ElementStyledContent },
  props: {
    therapyChoice: {
      type: Object as () => ITherapyChoiceMCQ,
      required: true,
    },
    uen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    content(): IStyleContent[] {
      if (this.therapyChoice.styleContent && this.therapyChoice.styleContent.length > 0 && this.therapyChoice.styleContent[0].key != StyleTypeEnum.CONTENT) {
        return this.therapyChoice.styleContent;
      }
      return [ { key: StyleTypeEnum.BODY, content: this.therapyChoice.content } ];
    },
  },
  mounted() {
    cssVars({});
  },
  methods: {
  },
});
