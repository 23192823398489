var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        { staticClass: "element-next-step-marker" },
        [
          _vm.allTherapiesComplete
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "library-explore elevation-0 card-border",
                      class: { "cursor-pointer": !_vm.actionButton },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.actionButton ? function() {} : _vm.goToTherapies()
                        }
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          class: {
                            "wrap pa-3": _vm.$vuetify.breakpoint.xsOnly,
                            "pa-2 pl-3": _vm.$vuetify.breakpoint.smAndUp
                          },
                          attrs: { "gap-xs-2": "" }
                        },
                        [
                          _c("v-flex", [
                            _c("h3", { staticClass: "starling-h3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.build_skills.all_complete.title"
                                  )
                                )
                              )
                            ]),
                            _c("p", { staticClass: "starling-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.build_skills.all_complete.subtitle"
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm.actionButton
                            ? _c(
                                "v-flex",
                                {
                                  attrs: {
                                    shrink: "",
                                    "text-center": "",
                                    "align-self-center": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      class: {
                                        "secondary-button": true,
                                        "mt-2": _vm.$vuetify.breakpoint.xsOnly,
                                        "mr-3": _vm.$vuetify.breakpoint.smAndUp
                                      },
                                      staticStyle: { "min-width": "180px" },
                                      attrs: { outline: "", round: "" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.goToTherapies($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              "app.products.modules.button_explore"
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : !_vm.therapyContent
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-card",
                    { staticClass: "checkup-recommended elevation-0" },
                    [
                      _c("v-flex", { attrs: { "py-2": "", "px-3": "" } }, [
                        _c("p", {
                          staticClass: "starling-body StarlingLightGrey--text",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "app.home.recommendations.build_skills.checkup_first.title"
                              )
                            )
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "library-explore elevation-0 mt-3" },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            "py-2": "",
                            "px-3": "",
                            "gap-xs-3": "",
                            wrap: _vm.$vuetify.breakpoint.xsOnly
                          }
                        },
                        [
                          _c("v-flex", [
                            _c("h3", { staticClass: "starling-h3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.build_skills.checkup_first.training_title"
                                  )
                                )
                              )
                            ]),
                            _c("p", { staticClass: "starling-text" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "app.home.recommendations.build_skills.checkup_first.training_subtitle"
                                  )
                                )
                              )
                            ])
                          ]),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                shrink: "",
                                "text-center": "",
                                "align-self-center": ""
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "secondary-button",
                                  attrs: { outline: "", round: "" },
                                  on: { click: _vm.goToTherapies }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "app.products.modules.button_explore"
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "element-next-step",
                          class: _vm.actionButton
                            ? "elevation-0"
                            : "cursor-pointer",
                          attrs: { hover: !_vm.actionButton },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.onCardClick($event)
                            }
                          }
                        },
                        [
                          _c("element-styled-content", {
                            attrs: {
                              content: _vm.therapyContent.styleContent,
                              uen: _vm.therapyContent.uen,
                              "is-card-content": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                _vm.actionLabel
                                  ? {
                                      key: "action",
                                      fn: function() {
                                        return [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                shrink: "",
                                                "text-center": "",
                                                "mt-2": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "primary-button",
                                                  attrs: { round: "" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.onActionButtonClick(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                " +
                                                      _vm._s(_vm.actionLabel) +
                                                      "\n              "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }