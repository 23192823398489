var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-msq", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        {
          class: _vm.isCardSelection
            ? "d-grid grid-cols-2 gap-xs-3 gap-md-2"
            : "gap-xs-2",
          attrs: { wrap: "", "justify-center": "" }
        },
        _vm._l(_vm.therapyContent.choices, function(choice, index) {
          return _c(
            "v-flex",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple",
                  value: { class: "success--text choice-ripple" },
                  expression: "{ class: 'success--text choice-ripple' }"
                }
              ],
              key: index,
              attrs: { xs12: !_vm.isCardSelection },
              on: {
                click: function($event) {
                  return _vm.selectChoice(choice)
                }
              }
            },
            [
              _c("element-msq-choice", {
                attrs: {
                  therapyChoice: choice,
                  uen: _vm.therapyContent.uen,
                  isCardSelection: _vm.isCardSelection
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }