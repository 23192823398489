










































import Vue from 'vue';
import { IStyleContent, ITherapyChoiceSubmenu, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import ElementPartGraphic from '@/views/products/components/elements-parts/element-part-graphic.vue';

export default Vue.extend({
  name: 'element-submenu-choice',
  components: {
    ElementStyledContent,
    ElementPartGraphic,
  },
  props: {
    choice: {
      type: Object as () => ITherapyChoiceSubmenu,
      required: true,
    },
    uen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      choiceStyle: null as string | null,
    };
  },
  computed: {
    header(): Array<IStyleContent> | null {
      const index = this.choice.styleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (index >= 0 && index !== this.choice.styleContent.length - 1) {
        return this.choice.styleContent.slice(0, index);
      }
      return null;
    },
    choiceContent(): Array<IStyleContent> {
      const index = this.choice.styleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (index >= 0) {
        if (index === this.choice.styleContent.length - 1) {
          return this.choice.styleContent.slice(0, index);
        }
        return this.choice.styleContent.slice(index + 1);
      }
      return this.choice.styleContent;
    },
    graphic(): IStyleContent | null {
      return this.choiceContent[0].key === StyleTypeEnum.GRAPHIC ? this.choiceContent[0] : null;
    },
    content(): Array<IStyleContent> {
      return this.graphic ? this.choiceContent.slice(1) : this.choiceContent;
    },
    showStatus(): boolean {
      return this.choice.locked || this.choice.initial || this.choice.started || this.choice.completed;
    },
  },
  methods: {
    selectItem() {
      if (this.choice.locked) return;
      this.$emit('choiceSelected', this.choice);
    },
    updateBackground(bgStyle: CSSStyleDeclaration) {
      if (!bgStyle || !this.$refs.choiceCard) return;

      const choiceCard = (this.$refs.choiceCard as Vue).$el as HTMLDivElement;
      if (this.choice.locked) {
        bgStyle.background = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), ' + bgStyle.background;
      }

      if (bgStyle.minHeight && bgStyle.width) {
        const minHeight = parseInt(bgStyle.minHeight.split('px')[0]);
        const backgroundSize = parseInt(bgStyle.backgroundSize.split('px auto')[0]);
        bgStyle.minHeight = (minHeight * choiceCard.offsetWidth / backgroundSize) + 'px';
      }

      bgStyle.backgroundSize = choiceCard.offsetWidth + 'px auto';

      const choiceCardStyle = choiceCard.style;
      choiceCardStyle.minHeight = bgStyle.minHeight;
      choiceCardStyle.background = bgStyle.background;
      choiceCardStyle.backgroundPosition = bgStyle.backgroundPosition;
      choiceCardStyle.backgroundSize = bgStyle.backgroundSize;
      choiceCardStyle.backgroundRepeat = bgStyle.backgroundRepeat;
      choiceCardStyle.backgroundColor = bgStyle.backgroundColor;

      this.choiceStyle = `height: ${bgStyle.height}px`;
    },
  },
});
