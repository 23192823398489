var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-workbook pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c("v-textarea", {
                    attrs: {
                      rules: !_vm.therapyContent.allowSkip
                        ? _vm.textFieldRules
                        : [],
                      placeholder: _vm.findPlaceholder(),
                      "hide-details": "",
                      outline: "",
                      "auto-grow": ""
                    },
                    on: { keyup: _vm.completeElement },
                    model: {
                      value: _vm.therapyContent.userAnswer,
                      callback: function($$v) {
                        _vm.$set(_vm.therapyContent, "userAnswer", $$v)
                      },
                      expression: "therapyContent.userAnswer"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }