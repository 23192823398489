var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loadedGoal
    ? _c(
        "v-layout",
        { staticClass: "element-goal", attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", lg9: "", "text-left": "" } },
            [
              !_vm.linkedGoal.id || _vm.editing
                ? _c("goal-form", {
                    attrs: {
                      goal: _vm.linkedGoal,
                      inline: "",
                      cancellable: _vm.editing
                    },
                    on: { update: _vm.onUpdate, cancel: _vm.onCancel }
                  })
                : _c("goal-card", {
                    attrs: { goal: _vm.linkedGoal },
                    on: { edit: _vm.onEdit, update: _vm.onUpdate }
                  })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }