var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { ref: "elementGroup", attrs: { fluid: "", "pa-0": "", "pb-3": "" } },
    [
      _c(
        "div",
        { ref: "elementGroupArea", staticClass: "elementGroupArea pb-5" },
        [
          _vm.testEnvironment
            ? _c("element-details", {
                attrs: { therapyGroup: _vm.therapyGroup }
              })
            : _vm._e(),
          _c(
            "v-scale-transition",
            {
              staticClass: "layout row wrap",
              class: { "pt-3": _vm.enableFirstMargin },
              attrs: {
                group: "",
                "leave-absolute": "",
                origin: "center center 0",
                tag: "div"
              }
            },
            _vm._l(_vm.visibleTherapyContent, function(therapyContent) {
              return _c("element-content", {
                key: therapyContent.uen,
                staticClass: "content-transition-item",
                attrs: {
                  id: "element-" + therapyContent.uen,
                  therapyContent: therapyContent
                },
                on: {
                  autoComplete: _vm.onAutoComplete,
                  completeElement: _vm.onCompleteElement,
                  updateBackground: _vm.updateBackground
                }
              })
            }),
            1
          ),
          _vm.showComments
            ? _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": "",
                    "pb-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", md10: "", xl8: "" } },
                    [
                      _c("element-comment-add", {
                        attrs: {
                          therapyContent:
                            _vm.therapyGroup.group[
                              _vm.therapyGroup.group.length - 1
                            ]
                        },
                        on: { commentAdded: _vm.onCommentAdded }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showComments
            ? _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": "",
                    "pb-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("element-comment-list", {
                        key: _vm.commentListKey,
                        attrs: {
                          therapyContent:
                            _vm.therapyGroup.group[
                              _vm.therapyGroup.group.length - 1
                            ]
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.showNextButton || _vm.showSkipButton
            ? _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": "",
                    "pt-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { staticClass: "text-center", attrs: { xs12: "" } },
                    [
                      _vm.showNextButton
                        ? _c(
                            "primary-button",
                            {
                              attrs: {
                                id: "course-player-btn-next",
                                large: ""
                              },
                              on: { click: _vm.doCompleteGroup }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.actionLabel) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm.showSkipButton
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ma-0",
                              attrs: {
                                id: "course-player-btn-skip",
                                round: "",
                                flat: "",
                                large: ""
                              },
                              on: { click: _vm.doSkipGroup }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("common.actions.skip")) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }