var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input-note-wrapper-element", {
        attrs: { note: _vm.note, loading: _vm.loading },
        on: { updated: _vm.onUpdated }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }