
























































































import Vue from 'vue';

export default Vue.extend({
  name: 'comment-reactions',
  props: {
    comment: {
      type: Object,
      required: true,
    },
    repliesLoading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isExpand: false,
      isHelpful: this.comment.isHelpful,
      isCare: this.comment.isCare,
      isThanks: this.comment.isThanks,
      helpfulLoading: false,
      careLoading: false,
      thanksLoading: false,
      isReplyOpen: false,
    };
  },
  computed: {
    isAuthor(): boolean {
      return Number(this.$store.getters.user.id) === this.comment.user.id;
    },
    replyCount(): String {
      const count = this.comment.replyCount;
      return count > 99 ? '99+' : count;
    },
    helpfulCount(): String {
      return this.comment.helpfulCount > 99 ? '99+' : this.comment.helpfulCount;
    },
    thanksCount(): String {
      return this.comment.thanksCount > 99 ? '99+' : this.comment.thanksCount;
    },
    careCount(): String {
      return this.comment.careCount > 99 ? '99+' : this.comment.careCount;
    },
    totalCount(): String {
      const count =
        this.comment.helpfulCount +
        this.comment.thanksCount +
        this.comment.careCount;
      return count > 99 ? '99+' : count;
    },
    helpfulStyle(): String {
      const style = 'z-index: 1000;';
      return style;
    },
    careStyle(): String {
      let style = 'z-index: 900;';
      if (Number(this.helpfulCount) > 0 && !this.isExpand) {
        style += 'margin-left:-10px;';
      }
      return style;
    },
    thanksStyle(): String {
      let style = 'z-index: 800;';
      if (
        (Number(this.careCount) > 0 || Number(this.helpfulCount) > 0) &&
        !this.isExpand
      ) {
        style += 'margin-left:-10px;';
      }
      return style;
    },
  },
  methods: {
    toggleReactionBar() {
      this.isExpand = !this.isExpand;
    },
    markHelpful() {
      const data = {
        commentId: this.comment.id,
        parentId: this.comment.parentId,
      };
      this.helpfulLoading = true;
      this.$store.dispatch('comments/toggleHelpful', data).then(res => {
        this.isHelpful = res.isHelpful;
      }).finally(() => {
        this.helpfulLoading = false;
        this.$emit('interacted');
      });
    },
    markCare() {
      const data = {
        commentId: this.comment.id,
        parentId: this.comment.parentId,
      };
      this.careLoading = true;
      this.$store.dispatch('comments/toggleCare', data).then(res => {
        this.isCare = res.isCare;
      }).finally(() => {
        this.careLoading = false;
        this.$emit('interacted');
      });
    },
    markThanks() {
      const data = {
        commentId: this.comment.id,
        parentId: this.comment.parentId,
      };
      this.thanksLoading = true;
      this.$store.dispatch('comments/toggleThanks', data).then(res => {
        this.isThanks = res.isThanks;
      }).finally(() => {
        this.thanksLoading = false;
        this.$emit('interacted');
      });
    },
    getReplies() {
      this.$emit('load-replies');
      if (this.isReplyOpen) {
        this.isReplyOpen = false;
      } else if (this.comment.replyCount > 0) {
        this.isReplyOpen = true;
      }
    },
  },
});
