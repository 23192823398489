var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "replies-container" },
        [
          _vm._l(_vm.shownReplies, function(reply, i) {
            return _c("comment-card", {
              key: reply.id,
              class: { last: i === _vm.shownReplies.length - 1 },
              style: "z-index:" + (998 - i) + ";",
              attrs: { comment: reply },
              on: {
                showGuidline: _vm.openGuidlineModal,
                openAddReply: function($event) {
                  return _vm.openAddReply(reply)
                },
                refreshList: _vm.onRefreshList,
                reportComment: function($event) {
                  return _vm.openReportModal(reply)
                }
              }
            })
          }),
          (_vm.replies.length && _vm.replyCount > _vm.replies.length) ||
          (_vm.replies.length && _vm.replyShown < _vm.replyCount)
            ? [
                _c(
                  "v-btn",
                  {
                    staticClass: "more-replies-button",
                    class: {
                      "is-author": _vm.isAuthor,
                      "is-moderator": _vm.isModerator
                    },
                    attrs: { round: "", loading: _vm.loading },
                    on: { click: _vm.loadMoreReplies }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("common.actions.load_more")) +
                        "\n    "
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.reportModalActive && _vm.reportedComment
        ? _c("report-modal", {
            attrs: {
              isActive: _vm.reportModalActive,
              comment: _vm.reportedComment
            },
            on: { closeReportModal: _vm.closeReportModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }