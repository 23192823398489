var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-video", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.therapyContent.url
                ? _c("vimeo-video", {
                    attrs: {
                      identifier: _vm.therapyContent.identifier,
                      videoId: _vm.therapyContent.url,
                      transcript: _vm.therapyContent.transcript
                    },
                    on: { videoEvent: _vm.onVideoEvent }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }