


























import Vue from 'vue';
import { IStyleContent, ITherapyContentPopup, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';

export default Vue.extend({
  name: 'element-popup',
  components: { ElementStyledContent },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentPopup,
      required: true,
    },
  },
  data() {
    return {
      showPopup: false,
    };
  },
  computed: {
    contentPart(): IStyleContent | undefined {
      return this.therapyContent.styleContent?.find(style => style.key === StyleTypeEnum.CONTENT);
    },
    automatic(): boolean {
      return !!this.contentPart?.automatic;
    },
    buttonLabel(): string {
      return this.contentPart?.content || this.$t('app.products.element.popup.tell_more_button').toString();
    },
  },
  mounted() {
    if (this.automatic) {
      this.openPopup();
    }
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
  },
});
