var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.feedback && _vm.feedbackContent
    ? _c(
        "div",
        { ref: "feedbackElement", staticClass: "element-feedback mb-3" },
        [
          _c(
            "v-expand-transition",
            [
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showFeedback,
                      expression: "showFeedback"
                    }
                  ],
                  attrs: { xs12: "" }
                },
                [
                  _c("v-divider", { staticClass: "mb-3" }),
                  _vm._l(_vm.feedbackContent, function(content, index) {
                    return _c("element-styled-content", {
                      key: index,
                      attrs: { content: content }
                    })
                  }),
                  _c("v-divider", { staticClass: "mt-0" })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }