var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "element-journal", attrs: { row: "", wrap: "" } },
    [
      _vm.loadedJournal && _vm.linkedJournal.id
        ? _c(
            "v-flex",
            { attrs: { xs12: "", lg9: "", "text-left": "" } },
            [
              _c("journal-card", {
                key: _vm.linkedJournal.id,
                attrs: { journal: _vm.linkedJournal, embedded: "" },
                on: { delete: _vm.onUpdate, update: _vm.onUpdate }
              })
            ],
            1
          )
        : _vm.loadedJournal
        ? _c(
            "v-flex",
            {
              staticClass: "element-journal-form",
              attrs: { xs12: "", lg9: "", "text-left": "" }
            },
            [
              _c("journal-form", {
                attrs: { journal: _vm.linkedJournal },
                on: { update: _vm.onUpdate }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }