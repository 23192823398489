var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "guideline-modal",
        persistent: "",
        width: _vm.dialogMaxWidth + "%"
      },
      model: {
        value: _vm.isActive,
        callback: function($$v) {
          _vm.isActive = $$v
        },
        expression: "isActive"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "guideline-card pa-3" },
        [
          _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0",
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeGuideline()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-card-title", { staticClass: "pa-0 -mt-2" }, [
            _c("h2", { staticClass: "starling-h2" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("app.products.comments.comment_card.guideline.title")
                )
              )
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("p", { staticClass: "starling-text pb-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "app.products.comments.comment_card.guideline.paragraph_first"
                    )
                  )
                )
              ]),
              _c("p", {
                staticClass: "starling-text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "app.products.comments.comment_card.guideline.subtitle_first"
                    )
                  )
                }
              }),
              _c(
                "ol",
                { staticClass: "starling-text pb-3" },
                _vm._l(
                  _vm.$t("app.products.comments.comment_card.guideline.rules"),
                  function(rule, index) {
                    return _c("li", { key: index }, [
                      _vm._v("\n          " + _vm._s(rule) + "\n        ")
                    ])
                  }
                ),
                0
              ),
              _c("p", { staticClass: "starling-text pb-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "app.products.comments.comment_card.guideline.paragraph_second"
                    )
                  )
                )
              ]),
              _c("p", {
                staticClass: "starling-text",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "app.products.comments.comment_card.guideline.subtitle_second"
                    )
                  )
                }
              }),
              _c("p", { staticClass: "starling-text pb-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "app.products.comments.comment_card.guideline.paragraph_third"
                    )
                  )
                )
              ]),
              _c(
                "v-layout",
                { staticClass: "mb-3", attrs: { "align-center": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { shrink: "" } },
                    [_c("v-icon", [_vm._v("mdi-heart-outline")])],
                    1
                  ),
                  _c("v-col", [
                    _c("p", {
                      staticClass: "starling-text ml-2",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "app.products.comments.comment_card.guideline.helpful"
                          )
                        )
                      }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "mb-3", attrs: { "align-center": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { shrink: "" } },
                    [_c("v-icon", [_vm._v("$vuetify.icons.starling_care")])],
                    1
                  ),
                  _c("v-col", [
                    _c("p", {
                      staticClass: "starling-text ml-2",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "app.products.comments.comment_card.guideline.care"
                          )
                        )
                      }
                    })
                  ])
                ],
                1
              ),
              _c(
                "v-layout",
                { staticClass: "mb-3", attrs: { "align-center": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { shrink: "" } },
                    [_c("v-icon", [_vm._v("$vuetify.icons.starling_thanks")])],
                    1
                  ),
                  _c("v-col", [
                    _c("p", {
                      staticClass: "starling-text ml-2",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "app.products.comments.comment_card.guideline.thanks"
                          )
                        )
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "primary-button",
                  {
                    attrs: { large: _vm.$vuetify.breakpoint.smAndUp },
                    on: {
                      click: function($event) {
                        return _vm.closeGuideline()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "app.products.comments.comment_card.guideline.button_action"
                          )
                        ) +
                        "\n        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }