































import Vue from 'vue';
import { IStyleContent, ITherapyChoiceCarousel, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';

export default Vue.extend({
  name: 'element-carousel-item',
  components: {
    ElementStyledContent,
  },
  props: {
    therapyChoice: {
      type: Object as () => ITherapyChoiceCarousel,
      required: true,
    },
    order: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    uen: {
      type: String,
      required: false,
    },
  },
  computed: {
    headerContent(): Array<IStyleContent> | undefined {
      if (!this.therapyChoice.feedbackStyleContent) return undefined;
      const index = this.therapyChoice.feedbackStyleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (index >= 0 && index !== this.therapyChoice.feedbackStyleContent.length - 1) {
        return this.therapyChoice.feedbackStyleContent.slice(0, index);
      }
      return undefined;
    },
    choiceContent(): Array<IStyleContent> | undefined {
      if (!this.therapyChoice.feedbackStyleContent) return undefined;
      const index = this.therapyChoice.feedbackStyleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (index < 0) return this.therapyChoice.feedbackStyleContent;
      if (index === this.therapyChoice.feedbackStyleContent.length - 1) {
        return this.therapyChoice.feedbackStyleContent.slice(0, index);
      }
      return this.therapyChoice.feedbackStyleContent.slice(index + 1);
    },
    isFirstPage(): boolean {
      return this.order <= 0;
    },
    isLastPage(): boolean {
      return this.order >= this.totalItems - 1;
    },
  },
  methods: {
    onClickNext () {
      this.$emit('next');
    },
    onClickPrevious () {
      this.$emit('previous');
    },
  },
});
