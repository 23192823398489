








import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
  name: 'starling-avatar-image',
  props: {
    username: {
      type: String,
      required: false,
    },
  },
  data() {
    return { stop: false, loaded: false };
  },
  computed: {
    ...mapState('identity', [ 'configuration' ]),
    user(): any {
      return this.$store.getters.user;
    },
    url(): string {
      return `${this.configuration.avatarUrl}/${this.username}.svg${this.user.codeName === this.username ? ('?' + new Date().getMilliseconds()) : ''}`;
    },
    defaultUrl(): string {
      return `${this.configuration.avatarUrl}/default.svg`;
    },
  },
  methods: {
    replaceByDefault(e: any) {
      if (!this.stop) {
        this.stop = true;
        e.target.src = this.defaultUrl;
      }
    },
  },
});
