var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.therapyContent.allowComments
    ? _c(
        "v-layout",
        {
          attrs: { row: "", wrap: "", "justify-center": "", "align-center": "" }
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("add-comment", {
                attrs: { therapyContent: _vm.therapyContent },
                on: { commentAdded: _vm.onCommentAdded }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }