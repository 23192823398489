var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    staticClass: "mr-1 starling-avatar-image",
    class: { loaded: _vm.loaded },
    attrs: { src: _vm.url },
    on: {
      error: _vm.replaceByDefault,
      load: function($event) {
        _vm.loaded = true
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }