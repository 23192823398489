var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.currentStep === 0
                ? _c("add-mood-emotions", {
                    key: _vm.currentStep,
                    attrs: {
                      form: _vm.form,
                      submit: _vm.advanceStep,
                      inline: _vm.inline
                    },
                    on: {
                      "update:form": function($event) {
                        _vm.form = $event
                      }
                    }
                  })
                : _c("add-mood-intensities", {
                    key: _vm.currentStep + 1,
                    attrs: {
                      mood: _vm.form,
                      loading: _vm.loading,
                      back: _vm.goBackStep,
                      inline: _vm.inline
                    },
                    on: { "mood-submit": _vm.submitMood }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }