var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.initialized
    ? _c(
        "v-layout",
        {
          staticClass: "element-slider",
          attrs: {
            row: "",
            wrap: "",
            "justify-center": "",
            "align-center": "",
            "mx-3": ""
          }
        },
        [
          !_vm.isBattery
            ? _c(
                "v-flex",
                { staticClass: "text-center", attrs: { xs12: "" } },
                [
                  _c("slider", {
                    attrs: {
                      value: _vm.value,
                      min: _vm.minValue,
                      max: _vm.maxValue,
                      color: "primary"
                    },
                    on: { change: _vm.onSliderChange }
                  })
                ],
                1
              )
            : _c(
                "v-flex",
                {
                  staticClass: "text-center",
                  attrs: { xs10: "", sm8: "", lg6: "", xl5: "" }
                },
                [
                  _c("battery", {
                    attrs: { level: _vm.value, allowSelection: "" },
                    on: { selected: _vm.onBatteryChange }
                  })
                ],
                1
              )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }