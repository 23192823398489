
<template>
  <v-container class="my-0 mx-2 pa-0">
    <v-card-actions class="pa-0 ma-0">
      <v-form lazy-validation ref="form" style="width: 100%">
        <v-textarea
          class="reply-comment-textarea"
          ref="replyInput"
          :id="`add-reply-${comment.id}`"
          v-model="text"
          :rules="textFieldRules"
          rows="1"
          auto-grow
          outline
          autofocus
          single-line
          hide-details
          :placeholder="textareaLabel"
          append-icon="mdi-close"
          @click:append="toggleVisible"
        />
      </v-form>
      <primary-button
        @click="postComment()"
        small
        round
        class="ml-1"
        :loading="isLoading"
      >
        {{ $t("common.actions.post") }}
      </primary-button>
    </v-card-actions>
  </v-container>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button';
export default {
  name: 'addReply',
  components: { PrimaryButton },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    reply: {
      type: [ Boolean, Object ],
      required: true,
    },
  },
  data() {
    return {
      text: '',
      isLoading: false,
      textFieldRules: [
        v => v.length <= 1024 || this.$t('common.errors.max1024'),
      ],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loadingSpinner;
    },
    textareaLabel() {
      return this.$t('app.products.comments.reply.label');
    },
  },
  mounted() {
    if (
      this.reply &&
      this.reply.user.id !== parseInt(this.$store.getters.user.id)
    ) {
      this.text = '@' + (this.reply.user.codeName || 'codename') + ' ';
    }
  },
  methods: {
    postComment() {
      const result = this.$refs.form.validate();
      if (result && this.text) {
        this.isLoading = true;
        const user = this.$store.getters.user;
        const data = {
          text: this.text,
          user: {
            id: user.id,
            avatar: user.avatar,
            codename: user.codename || 'nocodename',
          },
          parentId: this.comment.parentId || this.comment.id,
          uen: this.comment.uen,
          localeId: this.comment.localeId,
          isHelpful: false,
          isDeleted: false,
        };

        this.$store.dispatch('comments/postReply', data).then(res => {
          this.$emit('replyAdded', res.id);
          this.isLoading = false;
          this.text = '';
        });
      }
    },
    toggleVisible() {
      this.$emit('hideReply');
    },
    selectUser(user) {
      const formattedInput = this.text.replace(/@/gi, 'SPLITME@');
      let input = formattedInput.split('SPLITME');
      input.pop();
      if (input.length) {
        input = input.join('');
      }
      input += '@' + user + ' ';
      this.text = input;
    },
  },
};
</script>

<style>
.reply-comment-textarea.v-textarea.v-text-field .v-input__slot {
  border-radius: 10px;
  background-color: white !important;
}
</style>
