<template>
<v-container fluid pa-0>
  <v-layout row wrap>
    <v-flex xs12>
      <add-mood-emotions
              :key="currentStep"
              v-if="currentStep === 0"
              :form.sync="form"
              :submit="advanceStep"
              :inline="inline">
      </add-mood-emotions>
      <add-mood-intensities
              :key="currentStep + 1"
              v-else
              :mood="form"
              @mood-submit="submitMood"
              :loading="loading"
              :back="goBackStep"
              :inline="inline">
      </add-mood-intensities>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import { MoodTypeEnum } from '@/scripts/store/modules/tools/moods/types';
import addMoodEmotions from '@/views/tools/moods/components/addMood/add-mood-emotions.vue';
import addMoodIntensities from '@/views/tools/moods/components/addMood/add-mood-intensities.vue';

export default {
  name: 'mood-form',
  components: {
    addMoodEmotions,
    addMoodIntensities,
  },
  props: {
    mood: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentStep: (!this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'selector')) ? 0 : 1,
      form: Object.assign({
        id: null,
        reasonText: '',
        date: new Date(),
        moodIntensities: Object.values(MoodTypeEnum).reduce((previous, value) => ({ ...previous, [value]: { selected: false, value: null } }), {}),
      }, this.mood ? this.mood : {}),
    };
  },
  methods: {
    advanceStep() {
      if (this.currentStep < 1 && !this.disableNext) this.currentStep += 1;
      this.$el.scrollIntoView();
    },
    goBackStep() {
      if (this.currentStep === 1) this.currentStep -= 1;
      this.$el.scrollIntoView();
    },
    submitMood(event) {
      this.$emit('mood-submit', event);
    },
  },
};
</script>
