var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _vm.debugEnabled
        ? _c(
            "div",
            { staticClass: "element-debug" },
            [
              _c(
                "v-chip",
                {
                  attrs: {
                    small: "",
                    color: _vm.$t(
                      "app.products.element_types." +
                        _vm.therapyContent.type +
                        ".color"
                    )
                  }
                },
                [
                  _c("v-icon", { staticClass: "pr-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.products.element_types." +
                            _vm.therapyContent.type +
                            ".icon"
                        )
                      )
                    )
                  ]),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "app.products.element_types." +
                            _vm.therapyContent.type +
                            ".title"
                        )
                      )
                    )
                  ])
                ],
                1
              ),
              _c("em", [_vm._v("- " + _vm._s(_vm.therapyContent.uen))])
            ],
            1
          )
        : _vm._e(),
      _c(
        "element-styled-content",
        {
          attrs: {
            content: _vm.therapyContent.styleContent,
            uen: _vm.therapyContent.uen
          },
          on: { updateBackground: _vm.updateBackground }
        },
        [
          _vm.componentName
            ? _c(_vm.componentName, {
                tag: "component",
                class: {
                  "pb-3": _vm.therapyContent.type !== _vm.types.ASSESSMENT
                },
                attrs: { therapyContent: _vm.therapyContent },
                on: { completeElement: _vm.onCompleteElement }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.feedback
        ? _c("element-feedback", { attrs: { feedback: _vm.feedback } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }