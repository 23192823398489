var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("comment-card", {
        class: { "opened-replies": _vm.showReplies },
        staticStyle: { "z-index": "1000" },
        attrs: { comment: _vm.comment, repliesLoading: _vm.repliesLoading },
        on: {
          toggleReplies: _vm.toggleReplies,
          openUnreadReplies: _vm.openUnreadReplies,
          openLatestReply: _vm.openLatestReply,
          openAddReply: function($event) {
            return _vm.onOpenAddReply(_vm.comment)
          },
          commentDeleted: _vm.onCommentDeleted,
          reportComment: function($event) {
            return _vm.openReportModal(_vm.comment)
          },
          showGuidline: _vm.openGuidlineModal,
          refreshList: _vm.onRefreshList
        }
      }),
      _vm.showReplies && !_vm.isThreadHidden
        ? _c("comment-replies", {
            attrs: {
              comment: _vm.comment,
              replies: _vm.comment.replies,
              "reply-shown": _vm.repliesShownCount,
              replyCount: _vm.comment.replyCount,
              addReplyVisible: _vm.showAddReply,
              loading: _vm.repliesLoading
            },
            on: {
              showGuidline: _vm.openGuidlineModal,
              refreshList: _vm.onRefreshList,
              loadMoreReplies: _vm.onLoadMoreReplies
            }
          })
        : _vm._e(),
      _vm.showAddReply
        ? _c("add-reply", {
            key: _vm.replyComment ? _vm.replyComment.id : _vm.comment.id,
            attrs: { comment: _vm.comment, reply: _vm.replyComment },
            on: { replyAdded: _vm.onReplyAdded, hideReply: _vm.closeAddReply }
          })
        : _vm._e(),
      _vm.reportModalActive && _vm.reportedComment
        ? _c("report-modal", {
            attrs: {
              isActive: _vm.reportModalActive,
              comment: _vm.reportedComment
            },
            on: { closeReportModal: _vm.closeReportModal }
          })
        : _vm._e(),
      _vm.guidlineModalActive
        ? _c("guideline-modal", {
            attrs: { isActive: _vm.guidlineModalActive },
            on: { closeGuidelineModal: _vm.closeGuidelineModal }
          })
        : _vm._e(),
      _vm.loading
        ? _c(
            "v-flex",
            {
              staticClass: "loading-container",
              attrs: { xs12: "", "text-xs-center": "", "mt-4": "", "mb-4": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { color: "#525f7f", width: "3", indeterminate: "" }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }