

































































import Vue from 'vue';
import { IComment, ICommentStatus } from '@/scripts/store/modules/comments/types';
import CommentCard from '@/views/products/components/comments/comment-card.vue';
import CommentReplies from '@/views/products/components/comments/comment-replies.vue';
import AddReply from '@/views/products/components/comments/add-reply.vue';
import ReportModal from '@/views/products/components/comments/report-modal.vue';
import GuidelineModal from '@/views/products/components/comments/guideline-modal.vue';

const GUIDELINE_VIEWED = 'guidelineViewedConfig';

export default Vue.extend({
  name: 'comment-parent',
  components: {
    CommentCard,
    CommentReplies,
    AddReply,
    ReportModal,
    GuidelineModal,
  },
  props: {
    comment: {
      type: Object as () => IComment,
      required: true,
    },
  },
  data() {
    return {
      reportModalActive: false,
      reportedComment: {} as IComment,
      replyComment: undefined as IComment | undefined,
      showAddReply: false,
      showReplies: false,
      repliesShownCount: 0,
      repliesLoading: false,
      guidlineModalActive: false,
      unreadRepliesLoaded: false,
    };
  },
  computed: {
    loading(): boolean {
      return this.$store.state.loadingSpinner;
    },
    user(): any {
      return this.$store.getters.user;
    },
    commentStatuses(): ICommentStatus[] {
      return this.$store.getters['comments/getCommentsStatuses'];
    },
    isThreadHidden(): boolean {
      const foundStatuses = this.commentStatuses.filter((cs: ICommentStatus) => (!this.comment?.parentId && cs.commentId == this.comment?.id && cs.replyId == this.comment?.id) || (!!this.comment?.parentId && cs.replyId == this.comment?.id));
      return foundStatuses.some((cs: ICommentStatus) => !!cs.hidden);
    },
  },
  methods: {
    loadLatestReply() {
      this.loadReplies().then(() => {
        this.repliesShownCount = 1;
      });
    },
    loadUnreadReplies() {
      this.repliesLoading = true;
      return this.$store
        .dispatch('comments/getUnreadReplies', { id: this.comment.id })
        .then(res => {
          this.repliesLoading = false;
          this.showReplies = res.content.length > 0;
          this.unreadRepliesLoaded = true;
          this.repliesShownCount = res.content.length;
          return res;
        });
    },
    loadReplies() {
      this.repliesLoading = true;
      return this.$store
        .dispatch('comments/getReplies', { id: this.comment.id })
        .then(res => {
          this.repliesLoading = false;
          this.unreadRepliesLoaded = false;
          this.showReplies = res.content.length > 0;
          this.repliesShownCount = 3;
          return res;
        });
    },
    openUnreadReplies() {
      this.loadUnreadReplies();
    },
    openLatestReply() {
      this.loadLatestReply();
    },
    toggleReplies() {
      if (!this.showReplies || this.unreadRepliesLoaded) {
        this.loadReplies();
      } else {
        this.showReplies = false;
        this.repliesShownCount = 0;
      }
    },
    onLoadMoreReplies() {
      if (this.unreadRepliesLoaded) {
        this.loadReplies();
      } else {
        this.repliesShownCount += 3;
      }
    },
    onOpenAddReply(replyComment: IComment) {
      this.replyComment = replyComment;
      this.showAddReply = true;
    },
    closeAddReply() {
      this.showAddReply = false;
      this.replyComment = undefined;
    },
    onReplyAdded() {
      this.closeAddReply();
      this.showReplies = true;
    },
    onCommentDeleted() {
      this.$emit('commentDeleted');
    },
    onRefreshList() {
      this.$emit('refreshList');
    },
    openReportModal(comment: IComment) {
      this.reportedComment = comment;
      this.reportModalActive = true;
    },
    closeReportModal() {
      this.reportModalActive = false;
      this.reportedComment = {} as IComment;
    },
    openGuidlineModal() {
      this.guidlineModalActive = true;
    },
    closeGuidelineModal() {
      this.guidlineModalActive = false;
      const localGuidelineViewed = localStorage.getItem(`${this.user.id}_${GUIDELINE_VIEWED}`);
      if (!localGuidelineViewed) {
        this.$store.dispatch('comments/markGuidelineViewed').then(() => {
          localStorage.setItem(`${this.user.id}_${GUIDELINE_VIEWED}`, this.$store.getters['comments/getGuidelineViewed']);
        }).catch((error) => {
          throw new Error(`Failed to mark guideline as viewed: ${error}`);
        });
      }
    },
  },
});
