var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "my-0 mx-2 pa-0" },
    [
      _c(
        "v-card-actions",
        { staticClass: "pa-0 ma-0" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              staticStyle: { width: "100%" },
              attrs: { "lazy-validation": "" }
            },
            [
              _c("v-textarea", {
                ref: "replyInput",
                staticClass: "reply-comment-textarea",
                attrs: {
                  id: "add-reply-" + _vm.comment.id,
                  rules: _vm.textFieldRules,
                  rows: "1",
                  "auto-grow": "",
                  outline: "",
                  autofocus: "",
                  "single-line": "",
                  "hide-details": "",
                  placeholder: _vm.textareaLabel,
                  "append-icon": "mdi-close"
                },
                on: { "click:append": _vm.toggleVisible },
                model: {
                  value: _vm.text,
                  callback: function($$v) {
                    _vm.text = $$v
                  },
                  expression: "text"
                }
              })
            ],
            1
          ),
          _c(
            "primary-button",
            {
              staticClass: "ml-1",
              attrs: { small: "", round: "", loading: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.postComment()
                }
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("common.actions.post")) + "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }