var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _vm.therapyContent
        ? _c(
            "element-styled-content",
            {
              class: { "pb-3": _vm.isOnLastPosition },
              attrs: {
                content: _vm.questionContent,
                uen: _vm.therapyContent.uen
              }
            },
            [
              _vm.question.type === _vm.questionTypes.SINGLESELECT
                ? _c("element-mcq", {
                    key: _vm.therapyContent.uen,
                    attrs: { therapyContent: _vm.therapyContent },
                    on: { completeElement: _vm.selectChoice }
                  })
                : _vm._e(),
              _vm.question.type === _vm.questionTypes.MULTISELECT
                ? _c("element-msq", {
                    key: _vm.therapyContent.uen,
                    attrs: { therapyContent: _vm.therapyContent },
                    on: { completeElement: _vm.selectChoice }
                  })
                : _vm._e(),
              _vm.question.type === _vm.questionTypes.SLIDER
                ? _c("element-slider", {
                    key: _vm.therapyContent.uen,
                    attrs: { therapyContent: _vm.therapyContent },
                    on: { completeElement: _vm.selectChoice }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }