


























import Vue from 'vue';
import { IStyleContentCUScheduler, ITherapyContentCUScheduler, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-cu-scheduler',
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentCUScheduler,
      required: true,
    },
  },
  data() {
    return {
      selected: undefined as number | undefined,
      choices: [
        { value: 2, text: this.$t('app.products.element.cu_scheduler.choices.two_weeks') },
        { value: 4, text: this.$t('app.products.element.cu_scheduler.choices.four_weeks') },
        { value: 6, text: this.$t('app.products.element.cu_scheduler.choices.six_weeks') },
        { value: 8, text: this.$t('app.products.element.cu_scheduler.choices.eight_weeks') },
      ],
    };
  },
  computed: {
    contentConfig(): IStyleContentCUScheduler | undefined {
      return this.therapyContent.styleContent?.find(style => style.key === StyleTypeEnum.CONTENT) as IStyleContentCUScheduler;
    },
  },
  mounted() {
    if (this.contentConfig?.weeks) {
      this.selected = parseInt(this.contentConfig.weeks.toString());
      this.selectChoice(this.selected);
    } else if (this.therapyContent.interval) {
      this.selected = this.therapyContent.interval;
      this.$emit('completeElement', { valid: true, oldSelection: true });
    } else {
      this.selected = this.choices[0].value;
    }
  },
  methods: {
    selectChoice(choice: number) {
      // eslint-disable-next-line vue/no-mutating-props
      this.therapyContent.interval = choice;
      this.$emit('completeElement', { valid: true });
    },
  },
});
