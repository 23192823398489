var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-popup", attrs: { fluid: "", "pa-0": "" } },
    [
      !_vm.automatic
        ? _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "", "text-center": "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "secondary-button",
                      attrs: { outline: "", large: "", round: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openPopup($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.buttonLabel) + "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": _vm.$vuetify.breakpoint.xsOnly ? "98%" : "800px",
            "content-class": "element-popup-opened radius-15",
            light: "",
            persistent: ""
          },
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "margin-top": "40px",
                    "margin-right": "-15px"
                  },
                  attrs: {
                    absolute: "",
                    light: "",
                    fab: "",
                    top: "",
                    right: "",
                    flat: "",
                    large: _vm.$vuetify.breakpoint.smAndUp
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.closePopup($event)
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
              _vm._l(_vm.therapyContent.choices, function(content, index) {
                return _c("element-styled-content", {
                  key: index,
                  attrs: {
                    content: content.styleContent,
                    uen: _vm.therapyContent.uen
                  }
                })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }