var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 mt-0 pt-0 pb-2" },
    [
      _vm.comment.replyCount > 0 ||
      _vm.comment.helpfulCount > 0 ||
      _vm.comment.careCount > 0 ||
      _vm.comment.thanksCount > 0
        ? _c(
            "div",
            [
              _c("v-divider", { staticClass: "divider" }),
              _c(
                "v-card-actions",
                { staticClass: "mx-0 my-1 pa-0" },
                [
                  _vm.comment.helpfulCount > 0
                    ? _c(
                        "div",
                        { style: _vm.helpfulStyle },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "reaction-icon",
                              attrs: { icon: "", color: "#ffe5e4", small: "" },
                              on: { click: _vm.toggleReactionBar }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "StarlingPaleRed", small: "" }
                                },
                                [_vm._v(" mdi-heart ")]
                              )
                            ],
                            1
                          ),
                          _vm.isExpand
                            ? _c(
                                "span",
                                {
                                  staticClass: "reaction-bar-text ml-1 mr-2",
                                  attrs: { small: "" }
                                },
                                [_vm._v(_vm._s(_vm.helpfulCount))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.comment.careCount > 0
                    ? _c(
                        "div",
                        { style: _vm.careStyle },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "reaction-icon",
                              attrs: { icon: "", color: "#ffefbe", small: "" },
                              on: { click: _vm.toggleReactionBar }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "activated",
                                  attrs: { color: "#FFB843", small: "" }
                                },
                                [_vm._v("$vuetify.icons.starling_care")]
                              )
                            ],
                            1
                          ),
                          _vm.isExpand
                            ? _c(
                                "span",
                                {
                                  staticClass: "reaction-bar-text ml-1 mr-2",
                                  attrs: { small: "" }
                                },
                                [_vm._v(_vm._s(_vm.careCount))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.comment.thanksCount > 0
                    ? _c(
                        "div",
                        { style: _vm.thanksStyle },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "reaction-icon",
                              attrs: { icon: "", color: "#b7f8f0", small: "" },
                              on: { click: _vm.toggleReactionBar }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "activated",
                                  attrs: { color: "StarlingDarkGreen" }
                                },
                                [_vm._v("$vuetify.icons.starling_thanks")]
                              )
                            ],
                            1
                          ),
                          _vm.isExpand
                            ? _c(
                                "span",
                                {
                                  staticClass: "reaction-bar-text ml-1 mr-2",
                                  attrs: { small: "" }
                                },
                                [_vm._v(_vm._s(_vm.thanksCount))]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", [
                    !_vm.isExpand && Number(_vm.totalCount) > 0
                      ? _c(
                          "span",
                          {
                            staticClass: "reaction-bar-text ml-2",
                            attrs: { small: "" }
                          },
                          [_vm._v(_vm._s(_vm.totalCount))]
                        )
                      : _vm._e()
                  ]),
                  !_vm.comment.parentId
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "reaction-bar-text",
                          attrs: {
                            small: "",
                            flat: "",
                            loading: _vm.repliesLoading,
                            disabled: _vm.comment.replyCount < 1,
                            color: _vm.isReplyOpen ? "#3A5981" : ""
                          },
                          on: { click: _vm.getReplies }
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "div",
                            {
                              staticClass: "pa-0 ma-0",
                              staticStyle: { "text-transform": "capitalize" }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.replyCount) +
                                  "\n          " +
                                  _vm._s(
                                    _vm.comment.replyCount > 1
                                      ? _vm.$t(
                                          "app.products.comments.comment_card.replies"
                                        )
                                      : _vm.$t(
                                          "app.products.comments.comment_card.reply"
                                        )
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-divider", { staticClass: "divider mb-1" })
            ],
            1
          )
        : !_vm.comment.parentId && !_vm.isAuthor
        ? _c(
            "div",
            [
              _c("v-chip", { staticClass: "tag-bar" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("app.products.comments.comment_card.first_respond")
                  )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-0 pa-0",
              attrs: { flat: "", loading: _vm.helpfulLoading },
              on: { click: _vm.markHelpful }
            },
            [
              _c(
                "v-icon",
                { attrs: { color: _vm.isHelpful ? "StarlingPaleRed" : "" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isHelpful ? "mdi-heart" : "mdi-heart-outline"
                      ) +
                      "\n      "
                  )
                ]
              ),
              _c("span", { staticClass: "ml-1 v-action-bar" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("app.products.comments.comment_card.helpful")
                    ) +
                    "\n      "
                )
              ]),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-0 pr-0",
              attrs: { flat: "", center: "", loading: _vm.careLoading },
              on: { click: _vm.markCare }
            },
            [
              _c(
                "v-icon",
                {
                  class: _vm.isCare ? "activated" : "",
                  attrs: { color: _vm.isCare ? "#FFB843" : "" }
                },
                [_vm._v("$vuetify.icons.starling_care")]
              ),
              _c("span", { staticClass: "ml-1 v-action-bar" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("app.products.comments.comment_card.care")) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { flat: "", loading: _vm.thanksLoading },
              on: { click: _vm.markThanks }
            },
            [
              _c("v-spacer"),
              _c(
                "v-icon",
                {
                  class: _vm.isThanks ? "activated" : "",
                  attrs: { color: _vm.isThanks ? "StarlingDarkGreen" : "" }
                },
                [_vm._v("$vuetify.icons.starling_thanks")]
              ),
              _c("span", { staticClass: "ml-1 v-action-bar" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("app.products.comments.comment_card.thanks")
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }