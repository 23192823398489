var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    { staticClass: "element-accordion elevation-0" },
    _vm._l(_vm.choices, function(choice, index) {
      return _c(
        "v-expansion-panel-content",
        {
          key: index,
          attrs: { lazy: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "py-2" },
                      [
                        choice.header
                          ? _c("element-styled-content", {
                              attrs: {
                                content: choice.header,
                                uen: _vm.therapyContent.uen,
                                removeBottomMargin: true
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pt-2",
              class: _vm.$vuetify.breakpoint.smAndDown ? "px-2" : "px-3"
            },
            [
              choice.content
                ? _c("element-styled-content", {
                    attrs: {
                      content: choice.content,
                      uen: _vm.therapyContent.uen
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }