<template>
<div class="modal-content">
  <h3 class="starling-h3 mb-2">{{ $t('app.products.comments.comment_card.hide_confirmation.title') }}</h3>
  <p class="starling-explanatory mb-3">{{ $t('app.products.comments.comment_card.hide_confirmation.subtitle') }}</p>
  <action-buttons
          :primary="{label : $t('common.actions.hide'), disabled : false, loading : false}"
          @primary-clicked="actionClick"
          :secondary="{label : $t('common.actions.back'),disabled : false, loading : false}"
          @secondary-clicked="closeModal">
  </action-buttons>
</div>
</template>

<script>
import actionButtons from '@/views/components/navigation/action-buttons.vue';

export default {
  name: 'comment-card-hide',
  components: {
    actionButtons,
  },
  props: [ 'closeModal' ],
  methods: {
    actionClick() {
      this.$emit('action');
    },
  },
};
</script>
