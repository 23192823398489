<template>
  <!-- eslint-disable-next-line vue/no-mutating-props -->
  <v-dialog v-model="isActive" content-class="guideline-modal" persistent :width="`${dialogMaxWidth}%`">
    <v-card class="guideline-card pa-3">
      <div class="text-right">
        <v-btn class="ma-0" icon @click="closeGuideline()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="pa-0 -mt-2">
        <h2 class="starling-h2">{{ $t('app.products.comments.comment_card.guideline.title') }}</h2>
      </v-card-title>
      <v-card-text class="pa-0">
        <p class="starling-text pb-3">{{ $t('app.products.comments.comment_card.guideline.paragraph_first') }}</p>
        <p class="starling-text" v-html="$t('app.products.comments.comment_card.guideline.subtitle_first')"></p>
        <ol class="starling-text pb-3">
          <li v-for="(rule, index) in $t('app.products.comments.comment_card.guideline.rules')" :key="index">
            {{ rule }}
          </li>
        </ol>
        <p class="starling-text pb-3">{{ $t('app.products.comments.comment_card.guideline.paragraph_second') }}</p>
        <p class="starling-text" v-html="$t('app.products.comments.comment_card.guideline.subtitle_second')"></p>
        <p class="starling-text pb-3">{{ $t('app.products.comments.comment_card.guideline.paragraph_third') }}</p>
        <v-layout align-center class="mb-3">
          <v-col shrink>
            <v-icon>mdi-heart-outline</v-icon>
          </v-col>
          <v-col>
            <p class="starling-text ml-2" v-html="$t('app.products.comments.comment_card.guideline.helpful')"></p>
          </v-col>
        </v-layout>
        <v-layout align-center class="mb-3">
          <v-col shrink>
            <v-icon>$vuetify.icons.starling_care</v-icon>
          </v-col>
          <v-col>
            <p class="starling-text ml-2" v-html="$t('app.products.comments.comment_card.guideline.care')"></p>
          </v-col>
        </v-layout>
        <v-layout align-center class="mb-3">
          <v-col shrink>
            <v-icon>$vuetify.icons.starling_thanks</v-icon>

          </v-col>
          <v-col>
            <p class="starling-text ml-2" v-html="$t('app.products.comments.comment_card.guideline.thanks')"></p>
          </v-col>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <div class="text-center">
          <primary-button @click="closeGuideline()" :large="$vuetify.breakpoint.smAndUp">
          {{ $t('app.products.comments.comment_card.guideline.button_action') }}
          </primary-button>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryButton from '@/views/components/button/primary-button';

export default {
  name: 'instructionModal',
  components: { PrimaryButton },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    dialogMaxWidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 400 / 12;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 800 / 12;
      }
      return 100;
    },
  },
  methods: {
    closeGuideline() {
      this.$emit('closeGuidelineModal');
    },
  },
};
</script>

<style>
.guideline-modal,
.guideline-card {
  border-radius: 15px;
}
</style>
