var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-speed-dial",
        {
          class: { "with-bottom-nav": _vm.$vuetify.breakpoint.smAndDown },
          attrs: { bottom: "", right: "", fixed: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function() {
                return [
                  _c(
                    "primary-button",
                    { attrs: { small: "", fab: "" } },
                    [_c("v-icon", [_vm._v("mdi-wrench")])],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "white--text",
                            attrs: {
                              fab: "",
                              color: _vm.debugEnabled ? "green" : "red"
                            },
                            on: { click: _vm.toggleDebug }
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("bug_report")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Toggle debug mode")])]
          ),
          _vm._l(_vm.reverseTherapyGroup, function(therapyContent) {
            return _c(
              "v-tooltip",
              {
                key: therapyContent.uen,
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "white--text",
                                attrs: {
                                  fab: "",
                                  color: _vm.$t(
                                    "app.products.element_types." +
                                      therapyContent.type +
                                      ".color"
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onSelectTherapyContent(
                                      therapyContent
                                    )
                                  }
                                }
                              },
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "app.products.element_types." +
                                        therapyContent.type +
                                        ".icon"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c("span", { staticClass: "text-uppercase" }, [
                  _vm._v(
                    _vm._s(therapyContent.uen) +
                      " (" +
                      _vm._s(
                        _vm.$t(
                          "app.products.element_types." +
                            therapyContent.type +
                            ".title"
                        )
                      ) +
                      ")"
                  )
                ])
              ]
            )
          })
        ],
        2
      ),
      _c(
        "v-dialog",
        {
          model: {
            value: _vm.hasSelected,
            callback: function($$v) {
              _vm.hasSelected = $$v
            },
            expression: "hasSelected"
          }
        },
        [
          _vm.selected
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-chip",
                        {
                          attrs: {
                            small: "",
                            color: _vm.$t(
                              "app.products.element_types." +
                                _vm.selected.type +
                                ".color"
                            )
                          }
                        },
                        [
                          _c("v-icon", { staticClass: "pr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "app.products.element_types." +
                                    _vm.selected.type +
                                    ".icon"
                                )
                              )
                            )
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "app.products.element_types." +
                                    _vm.selected.type +
                                    ".title"
                                )
                              ) +
                              "\n        "
                          )
                        ],
                        1
                      ),
                      _vm._v(
                        "\n        " + _vm._s(_vm.selected.uen) + "\n        "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", flat: "" },
                          on: { click: _vm.onClose }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c("v-card-text", { staticClass: "pa-2" }, [
                    _c(
                      "pre",
                      {
                        staticClass: "break-line",
                        staticStyle: {
                          "font-size": "0.5rem",
                          "line-height": "140%"
                        }
                      },
                      [_vm._v(_vm._s(_vm.therapyContentAsString))]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }