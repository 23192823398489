



















import Vue from 'vue';
import { ITherapyChoiceSlider, ITherapyContentSlider, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import Battery from '@/views/home/components/battery.vue';
import Slider from '@/views/components/slider.vue';

export default Vue.extend({
  name: 'element-slider',
  components: { Battery, Slider },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentSlider,
      required: true,
    },
  },
  data() {
    return {
      value: null as number | null,
      showResults: true,
      initialized: false,
    };
  },
  computed: {
    selected(): ITherapyChoiceSlider | null {
      return this.therapyContent.choices.find(c => c.selected) as ITherapyChoiceSlider;
    },
    minValue(): number {
      if (!this.therapyContent.choices) return 0;
      const values = this.therapyContent.choices.map(c => parseInt(c.content));
      return Math.min(...values);
    },
    maxValue(): number {
      if (!this.therapyContent.choices) return 0;
      const values = this.therapyContent.choices.map(c => parseInt(c.content));
      return Math.max(...values);
    },
    isBattery(): boolean {
      return !!this.therapyContent.styleContent?.some(sc => sc.key === StyleTypeEnum.CONTENT && sc.content?.toUpperCase() === 'BATTERY');
    },
  },
  mounted() {
    if (this.selected) {
      this.$log.debug('The slider already has a selected value', this.selected);
      const multiplyBy = this.therapyContent.styleContent?.some(sc => sc.key === StyleTypeEnum.CONTENT && sc.content?.toUpperCase() === 'BATTERY') ? 10 : 1;
      this.value = parseInt(this.selected.content) * multiplyBy;
      this.$emit('completeElement', { valid: true, feedback: [ this.selected.feedbackStyleContent ], oldSelection: true });
    } else {
      this.$log.debug('The slider does not have a previously selected value');
      this.value = null;
    }
    this.initialized = true;
  },
  methods: {
    onSliderChange(value: number) {
      let feedbackStyleContent = null;
      this.therapyContent.choices.forEach(c => {
        if (parseInt(c.content) === value) {
          c.selected = true;
          feedbackStyleContent = c.feedbackStyleContent;
        } else {
          c.selected = false;
        }
      });
      this.$emit('completeElement', { valid: true, feedback: [ feedbackStyleContent ] });
    },
    onBatteryChange(value: number) {
      this.value = value;
      this.onSliderChange(value ? value / 10 : 0);
    },
  },
});
