var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.loading
        ? _c(
            "v-flex",
            {
              staticClass: "loading-container",
              attrs: { xs12: "", "text-xs-center": "", "my-3": "" }
            },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", width: "3", indeterminate: "" }
              })
            ],
            1
          )
        : _vm.totalComments
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "", "mb-3": "" } },
              [
                _c(
                  "v-layout",
                  {
                    attrs: {
                      "align-center": "",
                      "justify-space-between": "",
                      "gap-xs-2": ""
                    }
                  },
                  [
                    _vm.commentsCount > 0
                      ? _c("v-flex", { attrs: { xs4: "" } }, [
                          _c("p", { staticClass: "starling-body" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$tc(
                                  "app.products.comments.comment_count",
                                  _vm.commentsCount
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c(
                      "v-flex",
                      { attrs: { xs4: "", "text-center": "" } },
                      [
                        _c("sort-menu", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.totalComments,
                              expression: "totalComments"
                            }
                          ],
                          attrs: {
                            value: _vm.sortSelected,
                            sortList: _vm.sortList,
                            actionClick: _vm.onSortComments
                          }
                        })
                      ],
                      1
                    ),
                    _c("v-spacer")
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-flex",
              {
                key: _vm.totalComments,
                staticClass: "comments-container",
                attrs: { xs12: "", "text-xs-center": "" }
              },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "", "gap-xs-3": "" } },
                  [
                    _vm._l(_vm.comments, function(comment, index) {
                      return _c(
                        "v-flex",
                        { key: comment.id, attrs: { xs12: "" } },
                        [
                          _c("comment-parent", {
                            key:
                              _vm.sortSelected + "-" + comment.id + "-" + index,
                            attrs: { comment: comment },
                            on: {
                              commentAdded: _vm.onCommentAdded,
                              refreshList: _vm.onRefreshList,
                              commentDeleted: _vm.onCommentDeleted
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _vm.totalComments > 5
                          ? _c("v-pagination", {
                              staticClass: "comments-pagination",
                              class: { mobile: _vm.$vuetify.breakpoint.xsOnly },
                              attrs: {
                                length: _vm.paginateLength,
                                "total-visible": 5,
                                circle: "",
                                light: ""
                              },
                              on: { input: _vm.loadComments },
                              model: {
                                value: _vm.page,
                                callback: function($$v) {
                                  _vm.page = $$v
                                },
                                expression: "page"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]
        : _c("v-flex", { attrs: { "text-center": "" } }, [
            _c("p", [
              _vm._v(_vm._s(_vm.$t("app.products.comments.empty.title")))
            ])
          ]),
      _vm.guidlineModalActive
        ? _c("guideline-modal", {
            attrs: { isActive: _vm.guidlineModalActive },
            on: { closeGuidelineModal: _vm.closeGuidelineModal }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }