































































import Vue from 'vue';
import { ITherapyContent, ITherapyContentGroup } from '@/scripts/store/modules/therapy/types';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'element-details',
  components: { PrimaryButton },
  props: {
    therapyGroup: {
      type: Object as () => ITherapyContentGroup,
      required: true,
    },
  },
  data() {
    return {
      selected: null as ITherapyContent | null,
      hasSelected: false,
      debugEnabled: false,
    };
  },
  computed: {
    reverseTherapyGroup(): Array<ITherapyContent> {
      return this.therapyGroup.group.slice().reverse();
    },
    therapyContentAsString(): string {
      return JSON.stringify(this.selected, null, 2);
    },
  },
  mounted() {
    this.selected = null;
    this.debugEnabled = this.$store.getters.debugEnabled;
  },
  methods: {
    onClose(): void {
      this.hasSelected = false;
      this.selected = null;
    },
    onSelectTherapyContent(therapyContent: ITherapyContent): void {
      this.hasSelected = true;
      this.selected = therapyContent;
    },
    toggleDebug() {
      this.debugEnabled = !this.debugEnabled;
      this.$store.commit('setDebugEnabled', this.debugEnabled);
    },
  },
});
