
















import Vue from 'vue';
import { ITherapyContentThoughts } from '@/scripts/store/modules/therapy/types';
import Checkmark from '@/views/components/utilities/checkmark.vue';
import ThoughtCard from '@/views/tools/thoughts/components/thought-card.vue';
import ThoughtForm from '@/views/tools/thoughts/components/thought-form.vue';
import { IThoughtBalancer } from '@/scripts/store/modules/tools/thoughts/types';

export default Vue.extend({
  name: 'element-thoughts',
  components: {
    Checkmark,
    ThoughtCard,
    ThoughtForm,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentThoughts,
      required: true,
    },
  },
  data() {
    return {
      loadedThought: false,
      loading: false,
      linkedThought: {} as IThoughtBalancer,
      key: 0,
      editing: false,
    };
  },
  mounted() {
    this.$log.debug('Thought element', this.therapyContent);
    this.$emit('completeElement', { valid: false });
    if (this.therapyContent.userThoughtsId) {
      this.$store.dispatch('thoughts/getThought', this.therapyContent.userThoughtsId).then(res => {
        if (res && !(res.status > 300)) {
          this.linkedThought = res;
          this.$emit('completeElement', { valid: true });
        }
      }).finally(
        () => {
          this.loadedThought = true;
        },
      );
    } else {
      this.loadedThought = true;
    }
  },
  methods: {
    createThought(thought: IThoughtBalancer) {
      this.loading = true;
      this.$store.dispatch('thoughts/postThought', thought)
        .then(res => {
          if (res && !(res.status > 300)) {
            this.linkedThought = res;
            // eslint-disable-next-line vue/no-mutating-props
            this.therapyContent.userThoughtsId = parseInt(this.linkedThought.id);
            this.$emit('completeElement', { valid: true });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateThought(thought: IThoughtBalancer) {
      this.loading = true;
      this.$store.dispatch('thoughts/editThought', thought)
        .then(res => {
          if (res && !(res.status > 300)) {
            this.linkedThought = res;
            this.$emit('completeElement', { valid: true });
            // eslint-disable-next-line vue/no-mutating-props
            this.therapyContent.userThoughtsId = parseInt(this.linkedThought.id);
          }
        })
        .finally(() => {
          this.loading = false;
          this.editing = false;
        });
    },
    editThought(thought: IThoughtBalancer) {
      this.$log.debug('edit thought');
      this.editing = true;
      this.key++;
      this.$emit('completeElement', { valid: false });
    },
    deleteThought(thought: IThoughtBalancer) {
      this.$log.debug('delete thought');
      this.$store.dispatch('thoughts/deleteThought', thought.id)
        .then(() => {
          this.key++;
          this.linkedThought = {} as IThoughtBalancer;
          // eslint-disable-next-line vue/no-mutating-props
          this.therapyContent.userThoughtsId = undefined;
          this.$emit('completeElement', { valid: false });
        })
        .finally(() => {
          this.editing = false;
        });
    },
  },
});
