var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "element-carousel", attrs: { row: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "radius-10",
              attrs: { color: "StarlingGanonBlue--bg", flat: "" }
            },
            [
              _c(
                "v-window",
                {
                  attrs: {
                    touch: { left: _vm.onClickNext, right: _vm.onClickPrevious }
                  },
                  model: {
                    value: _vm.currentPage,
                    callback: function($$v) {
                      _vm.currentPage = $$v
                    },
                    expression: "currentPage"
                  }
                },
                _vm._l(_vm.therapyContent.choices, function(choice, index) {
                  return _c(
                    "v-window-item",
                    { key: "card-" + index, ref: "windowItem", refInFor: true },
                    [
                      _vm.currentPage === index
                        ? _c("element-carousel-item", {
                            attrs: {
                              "therapy-choice": choice,
                              order: index,
                              "total-items": _vm.therapyContent.choices.length,
                              uen: _vm.therapyContent.uen
                            },
                            on: {
                              next: _vm.onClickNext,
                              previous: _vm.onClickPrevious
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pa-0 pb-3" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "align-center": "",
                        "justify-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-item-group",
                            {
                              staticClass: "text-xs-center",
                              attrs: { mandatory: "" },
                              model: {
                                value: _vm.currentPage,
                                callback: function($$v) {
                                  _vm.currentPage = $$v
                                },
                                expression: "currentPage"
                              }
                            },
                            _vm._l(_vm.therapyContent.choices, function(
                              choice,
                              index
                            ) {
                              return _c("v-item", {
                                key: "btn-" + index,
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var toggle = ref.toggle
                                        return _c(
                                          "v-btn",
                                          {
                                            class: {
                                              first: index === 0,
                                              last: index === _vm.currentPage,
                                              highlight: !!_vm.footerLabel
                                            },
                                            attrs: {
                                              small:
                                                _vm.$vuetify.breakpoint.xsOnly,
                                              "input-value":
                                                index <= _vm.currentPage,
                                              icon: ""
                                            },
                                            on: { click: toggle }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "starling-explanatory"
                                              },
                                              [_vm._v(_vm._s(choice.content))]
                                            )
                                          ]
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.footerLabel
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "d-flex": "",
                                "text-center": ""
                              }
                            },
                            [
                              _c("span", { staticClass: "starling-citation" }, [
                                _vm._v(_vm._s(_vm.footerLabel))
                              ])
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }