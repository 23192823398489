








import Vue from 'vue';
import { ITherapyChoiceSubmenu, ITherapyContentSubmenu } from '@/scripts/store/modules/therapy/types';
import cssVars from 'css-vars-ponyfill';
import ElementSubmenuChoice from '@/views/products/components/elements/element-submenu-choice.vue';

export default Vue.extend({
  name: 'element-submenu',
  components: {
    ElementSubmenuChoice,
  },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentSubmenu,
      required: true,
    },
  },
  mounted() {
    cssVars({});
    this.$emit('completeElement', { valid: false, autoComplete: true });
  },
  methods: {
    choiceSelected(item: ITherapyChoiceSubmenu) {
      this.therapyContent.choices.forEach(choice => {
        choice.selected = (choice.id === item.id);
      });
      this.$emit('completeElement', { valid: true, autoComplete: true });
    },
  },
});
