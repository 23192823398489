














import Vue from 'vue';
import ElementMsqChoice from '@/views/products/components/elements/element-msq-choice.vue';
import { ITherapyChoiceMSQ, ITherapyContentMSQ, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-msq',
  components: { ElementMsqChoice },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentMSQ,
      required: true,
    },
  },
  computed: {
    selected(): ITherapyChoiceMSQ[] | undefined {
      const selected = this.therapyContent.choices.filter(c => c.selected);
      return selected && selected.length ? selected : undefined;
    },
    isCardSelection(): boolean {
      return !!this.therapyContent.styleContent?.some(part => part.key === StyleTypeEnum.CONTENT && !!part.card);
    },
  },
  mounted() {
    if (this.selected) {
      this.$emit('completeElement', { valid: true, feedback: this.selected?.map(selectedChoice => selectedChoice.feedbackStyleContent), oldSelection: true });
    }
  },
  methods: {
    selectChoice(choice: ITherapyChoiceMSQ) {
      choice.selected = !choice.selected;
      this.$emit('completeElement', { valid: true, feedback: this.selected?.map(selectedChoice => selectedChoice.feedbackStyleContent) });
    },
  },
});
