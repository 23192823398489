






import Vue from 'vue';
import { ITherapyContentNote } from '@/scripts/store/modules/therapy/types';
import { INote } from '@/scripts/store/modules/tools/notes/types';
import InputNoteWrapperElement from '@/views/tools/notes/input-note-wrapper-element.vue';

export default Vue.extend({
  name: 'ElementNote',
  components: { InputNoteWrapperElement },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentNote,
      required: true,
    },
  },
  data() {
    return {
      note: {
        id: undefined,
        note: '',
        date: new Date(),
        type: 'GENERAL',
        uen: '',
      } as INote,
      notesDate: new Date().toISOString(),
      loading: false,
    };
  },
  mounted() {
    if (this.therapyContent.userNoteId) {
      this.fetchNote();
    }
    this.$emit('completeElement', { valid: true });
  },
  methods: {
    fetchNote() {
      this.loading = true;
      try {
        this.$store.dispatch('notes/getNote', this.therapyContent.userNoteId).then(res => {
          if (!res || res.status >= 300) throw new Error();
          this.note = { ...res };
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        this.loading = false;
      }
    },
    onUpdated(updatedNote: INote) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const state = {
        ...updatedNote,
        uen: this.therapyContent.uen,
      };
      try {
        this.loading = true;
        this.$store.dispatch('notes/saveNote', state).then(res => {
          // eslint-disable-next-line vue/no-mutating-props
          this.therapyContent.userNoteId = state.id ? parseInt(state.id) : undefined;
          // eslint-disable-next-line vue/no-mutating-props
          this.therapyContent.userAnswer = state.note ? state.note : undefined;
          this.note = res;
          this.$emit('completeElement', { valid: true });
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    },
  },
});
