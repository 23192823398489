var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.visibilityChanged,
          expression: "visibilityChanged"
        }
      ],
      staticClass: "comment-card pa-2",
      class: {
        "mt-0": !_vm.comment.parentId,
        "is-reply": _vm.comment.parentId,
        "is-author": _vm.isAuthor,
        "is-moderator": _vm.isModerator,
        interacted: _vm.interacted && _vm.comment.parentId
      },
      attrs: { id: "comment-" + _vm.comment.id }
    },
    [
      _vm.showHideConfirmation
        ? [
            _c(
              "v-card-text",
              { staticClass: "pa-0 top-spacing" },
              [
                _c("comment-card-hide", {
                  attrs: {
                    closeModal: function() {
                      _vm.showHideConfirmation = false
                    }
                  },
                  on: { action: _vm.onHideConfirm }
                })
              ],
              1
            )
          ]
        : _vm.showBlockConfirmation
        ? [
            _c(
              "v-card-text",
              { staticClass: "pa-0 top-spacing" },
              [
                _c("comment-card-block", {
                  attrs: {
                    closeModal: function() {
                      _vm.showBlockConfirmation = false
                    }
                  },
                  on: { action: _vm.onBlockConfirm }
                })
              ],
              1
            )
          ]
        : [
            _c(
              "v-card-title",
              { staticClass: "pa-0 top-spacing" },
              [
                _vm.isUnread || (_vm.initiallyUnread && _vm.comment.parentId)
                  ? _c(
                      "div",
                      {
                        staticClass: "unread-marker",
                        on: { click: _vm.markAsRead }
                      },
                      [_vm._v(" \n      ")]
                    )
                  : _vm._e(),
                _c("span", { staticClass: "text-left" }, [
                  _c(
                    "div",
                    { staticClass: "headline" },
                    [
                      _vm.isHidden
                        ? _c(
                            "v-icon",
                            {
                              staticClass:
                                "comment-avatar text-center mr-1 StarlingDarkGrey--text"
                            },
                            [_vm._v("\n            visibility_off\n          ")]
                          )
                        : _vm.isModerator
                        ? _c(
                            "svg",
                            {
                              staticClass: "comment-avatar mr-1",
                              staticStyle: {
                                height: "1.75rem",
                                "vertical-align": "bottom"
                              },
                              attrs: { "aria-hidden": "true" }
                            },
                            [
                              _c("use", {
                                attrs: { "xlink:href": "#mini-jenny" }
                              })
                            ]
                          )
                        : _c("starling-avatar-image", {
                            attrs: { username: _vm.comment.user.codeName + "" }
                          }),
                      _c(
                        "span",
                        {
                          class: _vm.isHidden
                            ? "StarlingDarkGrey--text"
                            : "StarlingPrimary1--text"
                        },
                        [_vm._v(_vm._s(_vm.username))]
                      )
                    ],
                    1
                  )
                ]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    staticClass: "ma-0",
                    attrs: { icon: "" },
                    on: {
                      click: function($event) {
                        _vm.menuActive = !_vm.menuActive
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("more_horiz")])],
                  1
                ),
                _vm.menuActive
                  ? _c(
                      "div",
                      { staticClass: "comment-card-menu elevation-1" },
                      _vm._l(_vm.actionList, function(item, index) {
                        return _c(
                          "small",
                          {
                            key: index + _vm.comment.id,
                            on: {
                              click: function($event) {
                                return _vm.menuSelect(item)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(_vm._s(item.icon))
                            ]),
                            _vm._v(
                              "\n          " + _vm._s(item.label) + "\n        "
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                !_vm.isHidden &&
                _vm.comment.therapyTitle &&
                _vm.comment.sessionTitle
                  ? _c("v-flex", { attrs: { xs12: "", "text-left": "" } }, [
                      _c("p", { staticClass: "starling-text py-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("app.products.comments.comment_card.from")
                            ) +
                            ": \n          "
                        ),
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.openPreview(_vm.comment.uen)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.comment.therapyTitle) +
                                " > " +
                                _vm._s(_vm.comment.sessionTitle) +
                                " > (" +
                                _vm._s(
                                  _vm.$t(
                                    "app.products.comments.comment_card.preview"
                                  )
                                ) +
                                ")\n          "
                            )
                          ]
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            !_vm.isHidden
              ? _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _vm.editActive
                      ? [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "edit-action-container reply-action-container",
                              attrs: { flat: "" }
                            },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "replyEditForm",
                                  staticClass: "transparent"
                                },
                                [
                                  _c("v-textarea", {
                                    staticClass: "comment-textarea",
                                    attrs: {
                                      id: "edit-reply-" + _vm.comment.id,
                                      label: _vm.textareaLabel,
                                      outline: "",
                                      "hide-details": ""
                                    },
                                    model: {
                                      value: _vm.editText,
                                      callback: function($$v) {
                                        _vm.editText = $$v
                                      },
                                      expression: "editText"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                { staticClass: "px-0 py-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: { small: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.editActive = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.actions.cancel"))
                                      )
                                    ]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-capitalize",
                                      attrs: {
                                        small: "",
                                        round: "",
                                        color: "primary",
                                        outline: "",
                                        loading: _vm.editLoading
                                      },
                                      on: { click: _vm.editComment }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("common.actions.save"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : [
                          !_vm.viewTranslation || !_vm.translatedText
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "primary--text text-italic":
                                      _vm.comment.user.moderator
                                  }
                                },
                                [_c("span", [_vm._v(_vm._s(_vm.comment.text))])]
                              )
                            : _c("div", [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "primary--text text-italic":
                                        _vm.comment.user.moderator
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.translatedText))
                                    ])
                                  ]
                                ),
                                _c("div", { staticClass: "mt-2" }, [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text--disabled text--italic"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-1",
                                          staticStyle: { "font-size": "18px" }
                                        },
                                        [_vm._v("error_outline")]
                                      ),
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "app.products.comments.comment_card.translated"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "a",
                                        { on: { click: _vm.hideTranslation } },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "app.products.comments.comment_card.translated_cancel"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              ]),
                          _c(
                            "small",
                            { staticClass: "StarlingAlmostBlack--text" },
                            [_vm._v(_vm._s(_vm.formattedTime))]
                          ),
                          _c("comment-reactions", {
                            attrs: {
                              comment: _vm.comment,
                              repliesLoading: _vm.repliesLoading
                            },
                            on: {
                              "load-replies": _vm.getReplies,
                              interacted: _vm.markAsRead
                            }
                          })
                        ]
                  ],
                  2
                )
              : _vm._e(),
            !_vm.isHidden
              ? _c(
                  "v-card-actions",
                  { staticClass: "pa-0" },
                  [
                    !_vm.showAddReply
                      ? _c(
                          "v-btn",
                          {
                            staticClass:
                              "text-capitalize reply-button pa-0 ma-0",
                            attrs: { block: "", outline: "" },
                            on: {
                              click: function($event) {
                                return _vm.openAddReply(_vm.comment)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t("common.actions.reply")) +
                                "\n      "
                            )
                          ]
                        )
                      : _c("add-reply", {
                          key: _vm.replyComment
                            ? _vm.replyComment.id
                            : _vm.comment.id,
                          attrs: {
                            comment: _vm.comment,
                            reply: _vm.replyComment
                          },
                          on: {
                            replyAdded: _vm.onReplyAdded,
                            hideReply: _vm.onReplyAdded
                          }
                        })
                  ],
                  1
                )
              : _vm._e()
          ],
      _c(
        "v-dialog",
        {
          attrs: {
            lazy: "",
            "max-width": "" + (_vm.$vuetify.breakpoint.xl ? "1000px" : "800px"),
            "content-class": "comment-card-preview radius-15"
          },
          model: {
            value: _vm.showPreview,
            callback: function($$v) {
              _vm.showPreview = $$v
            },
            expression: "showPreview"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "banner-container", attrs: { flat: "" } },
            [
              _c(
                "div",
                { staticClass: "close-button" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showPreview = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "px-3" },
                [
                  _vm.previewTherapy
                    ? _c("element-view", {
                        attrs: {
                          therapyGroup: _vm.previewTherapy,
                          isPreview: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }