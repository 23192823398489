var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "report-modal",
        width: _vm.dialogMaxWidth + "%"
      },
      on: { input: _vm.closeModel },
      model: {
        value: _vm.showModel,
        callback: function($$v) {
          _vm.showModel = $$v
        },
        expression: "showModel"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "report-card" },
        [
          _c(
            "div",
            { staticClass: "text-right pr-3 pt-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0",
                  attrs: { icon: "" },
                  on: { click: _vm.closeModel }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-card-title", [
            _c(
              "h2",
              {
                staticClass: "starling-h2 pl-1 pr-3 pb-3",
                staticStyle: { display: "block", width: "100%" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("app.products.comments.comment_card.report.title")
                    ) +
                    "\n      "
                )
              ]
            ),
            _c("p", { staticClass: "starling-body pl-1 pr-1" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("app.products.comments.comment_card.report.subtitle")
                )
              )
            ])
          ]),
          _c("v-card-text", [
            _c("div", { staticClass: "poll" }, [
              _c(
                "ul",
                { staticClass: "module-steps selectable-list" },
                _vm._l(
                  _vm.$t("app.products.comments.comment_card.report.reasons"),
                  function(choice, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "pl-3 pr-3 reason-item",
                        class: {
                          "is-selected": _vm.reportReason === choice,
                          "reason-selected": _vm.reportReason === choice
                        },
                        on: {
                          click: function($event) {
                            _vm.reportReason = choice
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "starling-body" }, [
                          _vm._v(_vm._s(choice))
                        ])
                      ]
                    )
                  }
                ),
                0
              )
            ])
          ]),
          _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "text-center pb-3" },
              [
                _c("v-btn", { on: { click: _vm.closeModel } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("common.actions.cancel")) +
                      "\n          "
                  )
                ]),
                _c(
                  "primary-button",
                  {
                    attrs: {
                      large: _vm.$vuetify.breakpoint.smAndUp,
                      loading: _vm.isLoading,
                      disabled: !_vm.reportReason
                    },
                    on: {
                      click: function($event) {
                        return _vm.reportComment()
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("common.actions.report")) +
                        "\n          "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }