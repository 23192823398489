
































import Vue from 'vue';
import { ITherapyChoiceDecision, ITherapyContentDecision } from '@/scripts/store/modules/therapy/types';
import cssVars from 'css-vars-ponyfill';

export default Vue.extend({
  name: 'element-decision',
  components: {},
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentDecision,
      required: true,
    },
  },
  mounted() {
    cssVars({});
    this.$emit('completeElement', { valid: false, autoComplete: true });
  },
  methods: {
    selectChoice(choice: ITherapyChoiceDecision) {
      this.therapyContent.choices.forEach(item => {
        item.selected = false;
      });
      choice.selected = true;
      this.$emit('completeElement', { valid: true, autoComplete: true });
    },
  },
});
