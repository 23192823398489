















import Vue from 'vue';
import ElementMcqChoice from '@/views/products/components/elements/element-mcq-choice.vue';
import { ITherapyChoiceMCQ, ITherapyContentMCQ } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-mcq',
  components: { ElementMcqChoice },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentMCQ,
      required: true,
    },
  },
  data() {
    return {
      showResults: true,
    };
  },
  computed: {
    selected(): ITherapyChoiceMCQ | null {
      return this.therapyContent.choices.find(c => c.selected) as ITherapyChoiceMCQ;
    },
  },
  mounted() {
    if (this.selected) {
      this.$emit('completeElement', { valid: true, feedback: [ this.selected.feedbackStyleContent ], oldSelection: true });
    }
  },
  methods: {
    selectChoice(choice: ITherapyChoiceMCQ) {
      this.therapyContent.choices.forEach(c => {
        c.selected = false;
      });
      choice.selected = true;
      this.$emit('completeElement', { valid: true, feedback: [ choice.feedbackStyleContent ] });
    },
  },
});
