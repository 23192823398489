





























































import Vue from 'vue';
import { IComment } from '@/scripts/store/modules/comments/types';
import CommentParent from './comment-parent.vue';
import SortMenu from '@/views/components/utilities/sort-menu.vue';
import GuidelineModal from '@/views/products/components/comments/guideline-modal.vue';

const GUIDELINE_VIEWED = 'guidelineViewedConfig';

export default Vue.extend({
  name: 'comments-container',
  components: {
    CommentParent,
    SortMenu,
    GuidelineModal,
  },
  props: {
    comments: {
      type: Array as () => Array<IComment>,
      required: true,
    },
    totalComments: {
      type: Number,
      required: false,
      default: 0,
    },
    activePage: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      page: this.activePage,
      sortSelected: undefined as string | undefined,
      guidlineModalActive: false,
    };
  },
  computed: {
    sortList(): string[] {
      return (this.$t('app.products.comments.sorts') as unknown) as string[];
    },
    commentsCount(): string {
      if (!this.totalComments) return '0';
      return this.totalComments > 99 ? '99+' : this.totalComments + '';
    },
    paginateLength(): number {
      if (!this.totalComments) return 1;
      return Math.floor((this.totalComments) / 5) + ((this.totalComments % 5) && 1);
    },
    loading(): boolean {
      return this.$store.state.loadingSpinner;
    },
    containerKey(): number {
      return this.comments.length;
    },
    user(): any {
      return this.$store.getters.user;
    },
  },
  watch: {
    activePage(newValue) {
      this.page = newValue;
    },
  },
  mounted() {
    this.sortSelected = this.sortList[0];
    this.getOrFetchGuidelineViewed();
  },
  methods: {
    onCommentAdded() {
      this.loadComments(this.page);
    },
    onCommentDeleted() {
      if (this.comments.length === 1) {
        this.loadComments(this.page - 1);
      } else {
        this.loadComments(this.page);
      }
    },
    onRefreshList() {
      this.loadComments(this.page);
    },
    onSortComments(selected: string) {
      this.sortSelected = selected;
      const sortBy = selected === this.sortList[2] ? 'reactionCount' : 'id';
      const sortDir = selected === this.sortList[1] ? 'ASC' : 'DESC';
      this.$emit('sortComments', sortBy, sortDir);
    },
    loadComments(page: number) {
      this.$emit('loadComments', page);
    },
    closeGuidelineModal() {
      this.guidlineModalActive = false;
      this.$store.dispatch('comments/markGuidelineViewed').then(() => {
        localStorage.setItem(`${this.user.id}_${GUIDELINE_VIEWED}`, this.$store.getters['comments/getGuidelineViewed']);
      }).catch((error) => {
        throw new Error(`Failed to mark guideline as viewed: ${error}`);
      });
    },
    async getOrFetchGuidelineViewed() {
      const localGuidelineViewed = localStorage.getItem(`${this.user.id}_${GUIDELINE_VIEWED}`);
      if (!localGuidelineViewed) {
        await this.$store.dispatch('comments/fetchGuidelineViewed');
        localStorage.setItem(`${this.user.id}_${GUIDELINE_VIEWED}`, this.$store.getters['comments/getGuidelineViewed']);
      }
      const updatedLocalGuidelineViewed = localStorage.getItem(`${this.user.id}_${GUIDELINE_VIEWED}`);
      this.guidlineModalActive = updatedLocalGuidelineViewed !== 'true';
    },
  },
});
