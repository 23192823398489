















import Vue from 'vue';
import { IStyleContent, ITherapyContentAccordion, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';

interface AccordionParts {
  header?: IStyleContent[],
  content?: IStyleContent[],
}

export default Vue.extend({
  name: 'element-accordion',
  components: { ElementStyledContent },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContentAccordion,
      required: true,
    },
  },
  data() {
    return {
      choices: [] as Array<AccordionParts>,
    };
  },
  mounted() {
    this.choices = this.therapyContent.choices.map(choice => {
      let header;
      const indexHeader = choice.styleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (indexHeader >= 0 && indexHeader !== choice.styleContent.length - 1) {
        header = choice.styleContent.slice(0, indexHeader);
      }

      let content;
      const indexContent = choice.styleContent.map(c => c.key).indexOf(StyleTypeEnum.CONTENT);
      if (indexContent >= 0) {
        if (indexContent === choice.styleContent.length - 1) {
          content = choice.styleContent.slice(0, indexContent);
        } else {
          content = choice.styleContent.slice(indexContent + 1);
        }
      } else {
        content = choice.styleContent;
      }

      return { header, content };
    });
  },
});
