












import Vue from 'vue';
import ElementStyledContent from '@/views/products/components/element-styled-content.vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';

export interface IFeedback {
  scrollOnInit: boolean,
  content: IStyleContent[]
}

export default Vue.extend({
  name: 'element-feedback',
  components: {
    ElementStyledContent,
  },
  props: {
    feedback: {
      type: Array as () => IFeedback[],
      required: true,
    },
  },
  data() {
    return {
      feedbackContent: null as Array<IStyleContent[]> | null,
      showFeedback: false,
    };
  },
  watch: {
    feedback: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value instanceof Array) {
          this.displayFeedback(value);
        } else {
          this.displayFeedback(new Array(value));
        }
      },
    },
  },
  mounted() {
    if (!this.feedback) return;

    if (this.feedback.some(feedback => feedback.scrollOnInit)) {
      this.feedbackContent = this.feedback.map(feedback => feedback.content);
      this.showFeedback = true;
    }
  },
  methods: {
    displayFeedback(value: IFeedback[]) {
      this.showFeedback = false;

      if (!value || value.every(feedback => !feedback.content)) return;

      const feedbackEl = this.$refs.feedbackElement as HTMLDivElement;

      if (!feedbackEl || !value.some(feedback => feedback.scrollOnInit)) {
        this.feedbackContent = value.map(feedback => feedback.content);
        this.showFeedback = true;
        return;
      }

      const offset = window.innerHeight ? window.innerHeight / 2 : 100;
      this.$vuetify.goTo(feedbackEl, { duration: 500, offset });

      setTimeout(() => {
        this.feedbackContent = value.map(feedback => feedback.content);
        this.showFeedback = true;
      }, 500);
    },
  },
});
