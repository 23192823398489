import { IStyleContent } from '../therapy/types';
import { INote } from '../tools/notes/types';
export enum EQuestionType {
  /* eslint-disable no-unused-vars */
  MULTISELECT = 'MULTISELECT',
  SINGLESELECT = 'SINGLESELECT',
  SLIDER = 'SLIDER',
  /* eslint-enable no-unused-vars */
}

export interface IUserAssessmentAnswerChoice {
  id: string,
  value: number,
}

export interface IUserAssessmentAnswer {
  id: string,
  choices: Array<IUserAssessmentAnswerChoice>,
}

export interface IUserAssessment {
  userAssessmentId: number,
  uen: string,
  assessmentId: number,
  questions: Array<IUserAssessmentAnswer>,
  notes?: INote,
}

export interface IUserAssessmentNote {
  userAssessmentId: number,
  notes: INote
}

export interface IAssessmentQuestionChoice {
  id: string,
  index: number,
  title: string,
  value: number,
  selected: boolean,
}

export interface IAssessmentQuestion {
  id: string,
  type: EQuestionType,
  categoryType: string,
  title: string,
  choices: Array<IAssessmentQuestionChoice>,
  name: string,
  styleContent: Array<IStyleContent>,
  answered: boolean
}

export interface IAssessment {
  userAssessmentId: number,
  id: number,
  type: string,
  name: string,
  title: string,
  subtitle: string,
  footer: string,
  questions: Array<IAssessmentQuestion>,
  currentQuestion: number,
  totalQuestions: number,
  hasMoreQuestions: boolean
  notesQuestion: string,
  notes: INote,
  notesEnabled: boolean
}

export interface IAssessmentScaleInterval {
  name: string,
  min: number | null,
  max: number | null,
}

export interface IAssessmentScale {
  assessmentId: string,
  min: number,
  max: number,
  intervals: Array<IAssessmentScaleInterval>,
}

export interface IAssessmentHistoryScore {
  value: number,
  name: string,
  description: string
}

export interface IAssessmentHistoryScores {
  [scaleName: string]: IAssessmentHistoryScore;
}

export interface IAssessmentHistoryChart {
  completed: string,
  assessmentId: number,
  assessmentName: string,
  scores: IAssessmentHistoryScores
}

export interface IAssessmentResult {
  completed: string,
  value: string,
}

export interface AssessmentState {
  assessments: Array<IAssessment>,
}
