var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-content" },
    [
      _c("h3", { staticClass: "starling-h3 mb-2" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "app.products.comments.comment_card.block_confirmation.title"
            )
          )
        )
      ]),
      _c("p", { staticClass: "starling-explanatory mb-3" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "app.products.comments.comment_card.block_confirmation.subtitle"
            )
          )
        )
      ]),
      _c("action-buttons", {
        attrs: {
          primary: {
            label: _vm.$t("common.actions.block"),
            disabled: false,
            loading: false
          },
          secondary: {
            label: _vm.$t("common.actions.back"),
            disabled: false,
            loading: false
          }
        },
        on: {
          "primary-clicked": _vm.actionClick,
          "secondary-clicked": _vm.closeModal
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }