































import Vue from 'vue';
import { ITherapyContent } from '@/scripts/store/modules/therapy/types';
import { IComment } from '@/scripts/store/modules/comments/types';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'add-comment',
  components: { PrimaryButton },
  props: {
    therapyContent: {
      type: Object as () => ITherapyContent,
      required: false,
    },
  },
  data() {
    return {
      valid: false,
      text: null as string | null,
      sheet: false,
      isLoading: false,
      textFieldRules: [
        (v: string) => !v || (v && v.length <= 1024) || this.$t('common.errors.max1024'),
      ],
    };
  },
  mounted() {
    this.valid = false;
  },
  methods: {
    postComment() {
      const form = this.$refs.form as HTMLFormElement;
      const result = form.validate();
      if (result) {
        if (this.text) {
          this.isLoading = true;
          const data = {
            text: this.text,
            uen: this.therapyContent.uen,
            isHelpful: false,
            isDeleted: false,
          } as IComment;

          this.$store.dispatch('comments/postComment', data)
            .then((res: IComment) => {
              // eslint-disable-next-line vue/no-mutating-props
              this.therapyContent.commentsCount = this.therapyContent.commentsCount ? (this.therapyContent.commentsCount + 1) : 1;
              this.sheet = false;
              this.text = '';
              this.isLoading = false;
              form.resetValidation();
              const comment = document.getElementById(`comment-${res.id}`);
              if (comment) comment.scrollIntoView();
              this.$emit('commentAdded');
            });
        }
      }
    },
  },
});
